import './App.scss';

import MainPage from './components/MainPage';
import Fullservices from './components/Fullservices';
import Fullaboutus from './components/Fullaboutus';
import Fullcontactus from './components/Fullcontactus';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import ContactEmailPopup from './components/ContactEmailPopup';
import { Helmet } from "react-helmet";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Cookiepolicy from './assets/Images/SolaceCookiePolicy.pdf'; 
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import CookiePolicy from './components/pages/CookiePolicy';
import { Login } from './components/login/Login';
import { Toaster } from 'sonner';
import Map from './components/Map/Map';


function App() {
  const [Popup, setPopup] = useState(false);
  const [Cookiespop, setCookiespop] = useState(false);
  // useEffect(() => {
  //   if (!localStorage.getItem("No")) {
  //     setCookiespop(true)
  //   }
  //   else {
  //     localStorage.setItem("No", 1)
  //   }
  // }, [])
  return (
    <div className="App">
      <Helmet>
        <tiitle>Introducing Solace Management</tiitle>
        <meta name='description' content={"Solace property management service replaces your physical property manager with a virtual one. Instead of relying on someone to keep everything up-to-date, our system does it all on autopilot — making the process of managing multiple properties easier than ever."} />
        { /* End standard metadata tags */}
        { /* Facebook tags */}
        {/* <meta property="og:type" content={type} /> */}
        <meta property="og:title" content={"Introducing Solace Management"} />
        <meta property="og:description" content={"Solace property management service replaces your physical property manager with a virtual one. Instead of relying on someone to keep everything up-to-date, our system does it all on autopilot — making the process of managing multiple properties easier than ever."} />
        { /* End Facebook tags */}
        { /* Twitter tags */}
        {/* <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} /> */}
      </Helmet>
      {/* {Popup &&
        <div className='Popup_Wrapper'>
          <div className='Popup_Inner_Wrapper'>
            <div className='Header'>
              <div>
                <div className="container-fluid contactContainer">
                  <ContactEmailPopup setPopup={setPopup} />
                </div>
              </div>
            </div>
          </div>
        </div>
      } */}
      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="myAwesomeCookieName2"
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        <div className='Text'>By clicking “Accept all cookies”, you agree Solace can store cookies on your device and disclose information in accordance with our{" "}<span onClick={e=>{window.location.href="/cookiepolicy"}} style={{textDecoration:"underline",color:"blue"}}>Cookie Policy</span></div>
        {/* <span style={{ fontSize: "10px" }}>Please accept the Cookies</span> */}
      </CookieConsent>
      {/* {Popup &&
        <div className='Popup_Wrapper'>
          <div className='Popup_Inner_Wrapper'>
            <div className='Header'>
              <div>
                <div className="container-fluid contactContainer">
                  <ContactEmailPopup setPopup={setPopup} />
                </div>
              </div>
            </div>
          </div>
        </div>
      } */}
      <Router>
        <Routes>
          <Route path='/' element={<MainPage setPopup={setPopup}></MainPage>} />
          <Route path='/Services' element={<Fullservices setPopup={setPopup}></Fullservices>} />
          <Route path='/Aboutus' element={<Fullaboutus setPopup={setPopup}></Fullaboutus>} />
          <Route path='/Contactus' element={<Fullcontactus setPopup={setPopup}></Fullcontactus>} />
          <Route path='/privacypolicy' element={<PrivacyPolicy setPopup={setPopup}></PrivacyPolicy>}></Route>
          <Route path='/cookiepolicy' element={<CookiePolicy setPopup={setPopup}></CookiePolicy>}></Route>
          <Route path='/Login' element={<Login setPopup={setPopup}></Login>}></Route>
          <Route path='/map' element={<Map setPopup={setPopup}></Map>}></Route>
        </Routes>
      </Router>



    </div>
  );
}

export default App;
