import '../styles/WhoAreWe.scss';
import homeimg3 from "../assets/Images/whoweare.png";
import $ from "jquery";
import IconlogoImg1 from "../assets/Images/IconlogoImg1.png";
import IconlogoImg2 from "../assets/Images/IconlogoImg2.png";
import IconlogoImg3 from "../assets/Images/IconlogoImg3.png";
import IconlogoImg4 from "../assets/Images/IconlogoImg4.png";

function WhoAreWe() {

    $('.moreless-button').click(function () {
        $('.moretext').slideToggle();
        if ($('.moreless-button').text() == "Read more") {
            $(this).text("Read less");
        } else {
            $(this).text("Read more");
        }
    });
    return (
        <div className="WhoAreWe">

            <div className='row'>
                <div className='col-md-6 img-container'>
                    <img className="img-fluid mx-auto d-block Whorwecss" src={homeimg3} />
                </div>
                <div className='col-md-6 content-wrapper'>
                    <div>
                        <h1>Who Are We</h1>
                        <p>With decades of experience under one roof, we know how
                            property management works. But more importantly — we know
                            how to make it work better. From independent estate agents to
                            large property management firms, we’ve delivered
                            transcational change at lightning pace....</p>
                        <a href='/Aboutus' className='moreless-button'>Read More</a>
                    </div>
                </div>
            </div>

            <div className='row container-fluid WhoAreWe-Img-Row'>
                <div className='col-md-3 WhoAreWe-Img-Content'>
                    <img className="IconlogoImgcss1" src={IconlogoImg1} alt="IconlogoImg1"></img>
                    <p className='IconText'> More time for<br /> your business </p>
                </div>
                <div className='col-md-3 WhoAreWe-Img-Content'>
                    <img className="IconlogoImgcss2" src={IconlogoImg2} alt="IconlogoImg2"></img>
                    <p className='IconText'>Remove staff <br />dependency </p>
                </div>
                <div className='col-md-3 WhoAreWe-Img-Content'>
                    <img className="IconlogoImgcss3" src={IconlogoImg3} alt="IconlogoImg3"></img>
                    <p className='IconText'> Reduce costs <br />up to 50%</p>
                </div>
                <div className='col-md-3 WhoAreWe-Img-Content'>
                    <img className="IconlogoImgcss4 " src={IconlogoImg4} alt="IconlogoImg4"></img>
                    <p className='IconText '> Move your agency<br /> to the cloud</p>
                </div>
            </div>
        </div>
    );
}

export default WhoAreWe;
