function PersonalDataCollection() {
  return (
    <section className="section">
      <div className="personal-data-collection-box margin-bottom-vsmall">
        <h2 className="heading-secondary margin-bottom-vsmall">How we collect personal data:</h2>
        <p className="para margin-bottom-vsmall">
          We collect personal data in a variety of ways, including:
        </p>
        <ul className="inner-list">
          <li className="para">
            <span className="highlight">Directly:</span> We collect personal data which you directly
            provide to us, including when you apply for one of our properties, through the ‘contact
            us’ form on our website or when you request our assistance via email, or over the
            telephone.
          </li>
          <li className="para">
            <span className="highlight">Indirectly:</span> We may collect personal data which you
            indirectly provide to us while interacting with us, such as when you use our website, in
            emails, over the telephone and in your online enquiries.
          </li>
          <li className="para">
            <span className="highlight"> From third parties:</span> We collect personal data from
            third parties, such as of your use of our website from our analytics and cookie
            providers and marketing providers. See the “Cookies” section below for more detail on
            the use of cookies.
          </li>
        </ul>
      </div>
    </section>
  );
}

export default PersonalDataCollection;
