import '../styles/Introduction.scss';
import $ from 'jquery';
import homeimg1 from "../assets/Images/himg1.png";
import homeimg2 from "../assets/Images/himg2.png";
import IntroCircle from "../assets/Images/IntroCircle.png";


function Introduction() {

  $('.moreless-button').click(function () {
    $('.moretext').slideToggle();
    if ($('.moreless-button').text() == "Read more") {
      $(this).text("Read less");
    } else {
      $(this).text("Read more");
    }
  });
  return (
    <div className="introduction_page">
      <div className='container-fluid home-image '>
        <div className="home-image-content">
          <div className='home-img-left'>
            <h1 className='headline1'>INTRODUCING<br />
              SOLACE<br /> MANAGEMENT<br /></h1>
            <p> Building better estate agents by<br /> powering your back office.</p>
          </div>
          <img className='home-image-content-img' src={homeimg1} alt="img1" />
          <div className='property-content card'>
            <p className='textproperty1'>"Property management<br /> made easier than ever"</p>
            <p className='textproperty'><p>Stonehenge Property Management
            </p></p>
            <a href='/Services' className='moreless-button'>Read More</a>
          </div>
          {/* <img className=' IntroCircle1' src={IntroCircle} alt="IntroCircle"></img> */}
        </div>
      </div>
    </div>
  );
}

export default Introduction;
