function PrivacDescriptionBox() {
  return (
    <section className="section">
      <div className="privacy-description-box ">
        <h1 className="heading-primary text-center margin-bottom-small margin-top-medium">
          SOLACE GROUP LTD - <br /> PRIVACY POLICY
        </h1>
        <p className="para margin-bottom-small">
          Solace Group Ltd, a company registered in England and Wales, with company number, 14545268
          (<span className="highlight">we</span>, <span className="highlight">us</span> or
          <span className="highlight"> our</span>), understands that protecting your personal data
          is important. This Privacy Policy sets out our commitment to protecting the privacy of
          personal data provided to us, or otherwise collected by us when providing our website and
          property management services (<span className="highlight">Services</span>) or when
          otherwise interacting with you.
        </p>
        <p className="para margin-bottom-small">
          It is important that you read this Privacy Policy together with any other detailed privacy
          notices we may provide when we are collecting or processing personal data about you so
          that you understand our privacy practices in relation to your data.
        </p>
      </div>
    </section>
  );
}

export default PrivacDescriptionBox;
