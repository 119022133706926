import '../styles/OurStory.scss';
// import SolaceServiceslogo from "../assets/Images/SolaceServiceslogo.png";
import ServiceBanner from "../assets/Images/Ourstory.jpeg";


function Services() {
    return (
        <div className="Ourstory">

            <div className='Services-img'>
                <img src={ServiceBanner} className='img-fuild'></img>
            </div>
            <div className='container-fluid wholecontainer'>
                <div className='row'>
                    <div className='col-md-6 inner-wrapper'>
                        <h1 className='ServicesTagServices'>Our Story</h1>
                        <p>To rent out a property, the necessary components are a landlord, tenant and an estate agent. Our aim is to virtually unite and be of service to these three parties. It’s no secret that digitalisation is happening, fast. By 2030 we hope to have a fully synchronised system whereby all three parties have all the tools necessary to virtually manage a property.</p>
                        <p>Before Solace was born, we started off as a small property management company. As our property portfolio grew, we were increasingly burdened with the task of management itself – however, as of yet no alternative solution to management had been made available.  We were doing it the only way we knew - the manual way.</p>
                        <p>That was until we figured out a better way. A more connected, easier way.</p>
                    </div>
                    <div className='col-md-6'>
                        <div className='ServicesParagraph'>
                            <p id="para1">What started as a simple digital task list, grew into a fully-fledged, all-encompassing virtual property management system. One that made our job a lot easier — and our lives a lot simpler. When we realised that this was a problem that the entire industry faced, we decided to bring it to the market. Now, there’s a virtual back office available for every estate agent that also wants to make the hard work — easy. We are proud to present our services to all our clients; making living, manging and owning exceptionally simple. Are you one of them?
                            </p>
                            <p>
                                To summarise, we resonate with the fact that the conceived notion of ‘property management’ is outdated. We endeavour to shape the future in this field and envision an innovative management experience.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Services;
