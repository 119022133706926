import React, { useState } from 'react';
import "../styles/Savingcalculator.scss";
import home from "../assets/Images/Union.svg";
import rectangle from "../assets/Images/Rectangle 11c.svg";

function Savingcalculator() {
    const [value, setValue] = useState(200);

    const handleslide = (event) => {
        let newValue = parseInt(event.target.value);
        if ([300, 500, 600, 700].includes(newValue)) {
            if (newValue === 300) newValue = value < 300 ? 200 : 400;
            if (newValue === 500) newValue = value < 500 ? 400 : 800;
            if (newValue === 600) newValue = value < 600 ? 400 : 800;
            if (newValue === 700) newValue = value < 700 ? 800 : 800;
        }
        setValue(newValue);
    };

    // const data = {
    //     100: { Managers: 50000, Assurance: 32000, savings: 18000 },
    //     200: { Managers: 100000, Assurance: 65000, savings: 35000 },
    //     300: { Managers: 147000, Assurance: 97000, savings: 50000 },
    //     400: { Managers: 195000, Assurance: 130000, savings: 65000 },
    //     500: { Managers: 245000, Assurance: 162000, savings: 83000 }
    //   };

    const data = {
        100: { Managers: 40000, Assurance: 18000, savings: 22000 },
        200: { Managers: 55000, Assurance: 36000, savings: 19000 },
        400: { Managers: 95000, Assurance: 72000, savings: 23000 },
        800: { Managers: 175000, Assurance: 144000, savings: 31000 }
    };

    const sliderStyle = {
        transition: 'background 0.3s ease',
        background: `linear-gradient(to right, #875af0 ${(value - 100) / 7}%, #a1a0a0 ${(value - 100) / 7}%)`
    };

    return (
        <div className='saving-calculator'>
            <div className='left'>
                <div className='text'>
                    <h1>Savings Calculator</h1>
                    <p>Multiply your savings with Solace</p>
                </div>
                <div className='left-properties'>
                    <div className='properties'>
                        <div className='property-manager'>
                            <p>Other Estate Agents</p>
                            <p className='price'>
                                <span className='doller'>£ </span>
                                <span>{data[value].Managers.toLocaleString()}</span>
                            </p>
                        </div>
                        <div className='solace-assurance'>
                            <p>Solace Partners</p>
                            <p className='price2'>
                                <span className='doller1'>£</span>
                                <span>{data[value].Assurance.toLocaleString()}</span>
                            </p>
                        </div>
                    </div>
                    <div className='slider-container'>
                        <p className='no-props'>No. of Properties</p>
                        <div className='home-value'>
                            <img src={home} alt="home" />
                            <p className='sl-value'>{value}</p>
                        </div>
                        <input
                            type='range'
                            min={100}
                            max={800}
                            step={100} 
                            value={value}
                            onChange={handleslide}
                            className='slider'
                            style={sliderStyle}
                        />
                        <div className='num'>
                            <p>100</p>
                            <p>800</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='right'>
                <div className='right-savings'>
                    <div className='rgt-card'>
                        <p className='sav-p'>Savings From Solace</p>
                        <div className='rgt-value'>
                            <p className='rgt-dol'>£ </p>
                            <p className='rgt-price'>{data[value].savings.toLocaleString()}</p>
                        </div>
                    </div>
                    <div className='get-started-btn'>
                        <button onClick={(e) => window.location.href = "/Contactus"}>Get Started</button>
                    </div>
                    <div className='rect-img'>
                        <img src={rectangle} alt="rectangle" className='rectangle-img' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Savingcalculator;
