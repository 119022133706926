import React, { useRef, useState } from 'react';
import NavBar from './NavBar';
import "../styles/MainPage.scss";
import Services from './Services';
import Whatwedo from './Whatwedo';
import Whatwedo1 from './Whatwedo1';
import WhatClientSays from './WhatClientSays';
import Footer from './Footer';
import BookAnAppointment from './BookAnAppointment';


function Fullservices(props){
  return(
      <div className='MainPage'>
          <NavBar setPopup={props.setPopup}>
          </NavBar>
          <Services></Services>
          <Whatwedo1></Whatwedo1>
          <WhatClientSays></WhatClientSays>
          {/* <BookAnAppointment></BookAnAppointment> */}
          <Footer></Footer>
      </div>
  )  
}

export default Fullservices;