import '../styles/Supercharge.scss';
import homeimg2 from "../assets/Images/himg2.png";
import $ from 'jquery';



function Supercharge() {

    $('.moreless-button1').click(function () {
        $('.moretext1').slideToggle();
        if ($('.moreless-button1').text() == "Read more") {
            $(this).text("Read less");
        } else {
            $(this).text("Read more");
        }
    });


    return (
        <div className="Supercharge">

            {/* <div className='row'>
                <div className='col-md-6 content-wrapper'>
                    <div>
                        <h1>Supercharge Your Portfolio In Just 60 days</h1>
                        <p>Solace is proud to announce the future of property management. We'd like to invite you to become a partner and take advantage of our professional services.
                        </p>
                        <a className='moreless-button'>Read More</a>
                    </div>
                </div>
                <div className='col-md-6 img-container'>
                    <img className="img-fluid mx-auto d-block Whorwecss" src={homeimg2} />
                </div>
            </div> */}

            <div className='container-fluid row home-image2'>
                <div className="img2-right-content">
                    <div className='img2-left-innercontent'>
                        <h1>Supercharge Your<br /> Portfolio In Just<br /> 60 days</h1>
                        <p>
                        Solace is proud to announce the future of <br/>property management. We'd like to invite you <br/> to become a partner and take advantage of <br/> our professional services.
                        </p>
                    </div>
                    <img src={homeimg2} />
                </div>
            </div>

        </div>
    );
}

export default Supercharge;
