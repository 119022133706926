/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "../styles/Contact.scss";
import "../styles/Services.css";
import validator from 'validator';
import Input from '@mui/material/Input';
import Swal from 'sweetalert2';
import pdf from '../assets/Images/privacydoc.pdf'


const ContactEmail = () => {
    const initialValues = {
        user_name: "",
        user_email: "",
        user_phone: "",
        user_address: "",
        message: ""
    };

    const form = useRef();
    const [data, setData] = useState(initialValues);
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [CheckBox, setCheckBox] = useState(false);
    const [CheckBoxError, setCheckBoxerror] = useState(false);

    const [submitPopup, setSubmitPopup] = useState('');


    const validateName = (e) => {
        var user_name = e.target.value;
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        if (validator.isEmpty(user_name.trim())) {
            setNameError('*Name cannot be empty!');
        } else {
            setNameError('');
        }
    };

    const validatePhone = (e) => {
        var user_phone = e.target.value;
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        if (validator.isEmpty(user_phone)) {
            setPhoneError("*Phone Number Cannot be empty!");
        }
        else if (validator.isMobilePhone(user_phone) && validator.isLength(e.target.value, { min: 10, max: 11 })) {
            setPhoneError("");
        } else {
            setPhoneError('*Enter Valid Phone Number!');
        }
    };

    const validateEmail = (e) => {
        var user_email = e.target.value;
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        if (validator.isEmpty(user_email)) {
            setEmailError("*Email Cannot be empty!");
        }
        else if (validator.isEmail(user_email)) {
            setEmailError('');
        } else {
            setEmailError('*Enter valid Email!');
        }
    };

    const validateAddress = (e) => {
        var user_address = e.target.value;
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        if (validator.isEmpty(user_address)) {
            setAddressError("*Address Cannot be empty!");
        }
        else {
            setAddressError('');
        }
    };

    const validateMessage = (e) => {
        var message = e.target.value;
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        if (validator.isEmpty(message)) {
            setMessageError("*Message Cannot be empty!");
        }
        else {
            setMessageError('');
        }
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if(!CheckBox){
            setCheckBoxerror(true)
        }

        if (data.user_name == '') {
            setNameError('*Name cannot be empty!');
        }
        if (data.user_email == '') {
            setEmailError('*Email cannot be empty!');
        }
        if (data.user_phone == '') {
            setPhoneError('*Phone cannot be empty!');
        }
        if (data.user_address == '') {
            setAddressError('*Address cannot be empty!');
            const div = document.getElementById("Address")
            div.setAttribute('value','Not Available')
        }
        if (data.message == '') {
            setMessageError('*Message cannot be empty!');
            const div1 = document.getElementById("Message")
            div1.setAttribute('value','Not Available')
        }

        console.log(e.target)

        if (data.user_name != '' && data.user_email != '' && data.user_phone != '' && CheckBox || data.message || data.user_address != '') {
            
           var showLoading = function() {
            Swal.fire({
                 html: 'Sending...',
                 showConfirmButton: false,
                 allowOutsideClick: false,
                  willOpen: () => {
                    Swal.showLoading ()
                  },

                  willClose: () => {
                    Swal.hideLoading ()
                  }
            })
        }
           
        showLoading();


            emailjs
                .sendForm(
                    "service_x9ynrb7",
                    "template_obut84l",
                    e.target,
                    "yUYG--FF99N1bjK7m"
                )
                .then(
                    (result) => {
                        console.log(e.target)
                        console.log(result.text);
                        console.log("message sent");

                        Swal.fire({
                            // icon: 'success',
                            // title: 'Message Sent Successfully',
                           text: 'Thank you for providing your details, we will get in touch shortly!',

                          })
                        e.target.reset();
                    },
                    function (error) {
                        console.log(error.text,"form error");

                        Swal.fire({
                            // icon: 'error',
                            // title: 'Message not sent',
                            showLoaderOnConfirm: true,
                            text: 'Oops!, Something went wrong... Please contact on info@solacemanagement.co.uk',

                          })
                    }
                );

            e.target.reset();
        }    



    };

    return (

        <form className="Contactform" ref={form} onSubmit={sendEmail}>
            <div className="row">
                <div className='col-md-5 EmailContent openingtitle'>
                    <Input className="InputBox" onChange={validateName} type="text" name="user_name" placeholder="Name" />
                    <p className={`FormErrors ${nameError != '' ? 'show' : 'hide'}`}>
                        {nameError}
                    </p>
                </div>
                <div className="col-2"></div>
                <div className='col-md-5 EmailContent openingtitlecol2'>
                    <Input className="InputBox" onChange={validateEmail} type="email" name="user_email" placeholder="Email" />
                    <p className={`FormErrors ${emailError != '' ? 'show' : 'hide'}`}>
                        {emailError}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className='col-md-5 EmailContent openingtitle'>
                    <Input className="InputBox" onChange={validatePhone} type="text" name="user_phone" placeholder="Phone" />
                    <p className={`FormErrors ${phoneError != '' ? 'show' : 'hide'}`}>
                        {phoneError}</p>
                </div>
                <div className="col-2"></div>
                <div className='col-md-5 EmailContent openingtitlecol2'>
                    <Input className="InputBox" id="Address" onChange={validateAddress} type="text" name="user_address" placeholder="Address" />
                    <p className={`FormErrors`}>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className='col-md-11 EmailContent openingtitle'>
                    <Input className="InputBox1" id="Message" onChange={validateMessage} name="message" placeholder="Type your message here..." />
                    <p className={`FormErrors`}></p>
                </div>
            </div>

            <div className="row">
                <div className='col-md-11 EmailContent openingtitle'>
                    <input type="checkbox" onChange={e => { if (CheckBox) { setCheckBox(false);setCheckBoxerror(false) } else { setCheckBox(true);setCheckBoxerror(false) } }} checked={CheckBox} name="message" placeholder="Type your message here..." /><span>
                        <a style={{marginLeft:"20px",fontSize:"15px",width: "40%", textAlign: "right", color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={e=>{window.location.href="/privacypolicy"}}>Agree our Privacy Policy and Terms of Service</a>
                    </span>
                    {CheckBoxError && <p className={`FormErrors`}>Plaese Agree our Terms and Conditions</p>}
                </div>
            </div>

            <div className="row">
                <div className='col-md-5 openingtitle1'>
                    <button className="InputBox2" type="submit" value="Submit" onSubmit={sendEmail} >Submit </button>
                </div>
            </div>
        </form >

    );
};

export default ContactEmail;