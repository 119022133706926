import React from "react";
import "../styles/NavBar.scss";

function Navlist() {
  return (
    <ul className="nav-ham">
      <li className="nav-item ham ">
        <a className="nav-link " aria-current="page" href="/Services">
          Services{" "}
        </a>
      </li>

      <li className="nav-item ham">
        <a className="nav-link" href="/Aboutus">
          About Us
        </a>
      </li>

      <li className="nav-item ham">
        <a className="nav-link " href="/Contactus">
          Contact
        </a>
      </li>
      <li className="nav-item ham">
        <a
          href="https://tenant.solacemanagement.co.uk/" // "https://devtenant.solacemanagement.co.uk/"
          target="_blank"
        >
          Tenant Login
        </a>
      </li>
      <li className="nav-item ham">
        <a
          href="https://landlord.solacemanagement.co.uk/" // "https://devlandlord.solacemanagement.co.uk/"
          target="_blank"
        >
          Landlord Login
        </a>
      </li>

      <li className="nav-item ham">
        <a
          href="https://client.solacemanagement.co.uk/" // "https://devclient.solacemanagement.co.uk/"
          target="_blank"
        >
          Client Login
        </a>
      </li>

      <li className="nav-item ham">
        <a
          href="https://app.solacemanagement.co.uk/" // "https://devapp.solacemanagement.co.uk/"
          target="_blank"
        >
          Admin Login
        </a>
      </li>
    </ul>
  );
}

export default Navlist;
