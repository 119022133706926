import PrivacDescriptionBox from "../PrivacyDocs/PrivacDescriptionBox";
import PrivacyInformationBox from "../PrivacyDocs/PrivacyInformationBox";
import PersonalDataCollection from "../PrivacyDocs/PersonalDataCollection";

import TabularColumn from "../PrivacyDocs/TabularColumn";
import Disclosures from "../PrivacyDocs/Disclosures";
import OverseasTransfers from "../PrivacyDocs/OverseasTransfers";
import DataRetention from "../PrivacyDocs/DataRetention";
import RightsAndControl from "../PrivacyDocs/RightsAndControl";
import StorageAndSecurity from "../PrivacyDocs/StorageAndSecurity";
import Cookies from "../PrivacyDocs/Cookies";

import Amendments from "../PrivacyDocs/Amendments";
import ContactInformation from "../PrivacyDocs/ContactInformation";
import "../../styles/Policy.css";
import NavBar from "../NavBar";
import Footer from "../Footer";

function PrivacyPolicy(props) {
  return (
    <section className="section-privacyPolicy">
      <NavBar setPopup={props.setPopup} />
      <div className="container1">
        <PrivacDescriptionBox />
        <PrivacyInformationBox />
        <PersonalDataCollection />

        <TabularColumn />

        <Disclosures />
        <OverseasTransfers />
        <DataRetention />
        <RightsAndControl />
        <StorageAndSecurity />
        <Cookies />
        <Amendments />
        <ContactInformation />
      </div>
      <Footer></Footer>
    </section>
  );
}

export default PrivacyPolicy;
