import React, { useEffect, useRef, useState } from 'react';
import "../styles/MainPage.scss";


// components
import NavBar from './NavBar';
import Introduction from './Introduction';
import Supercharge from './Supercharge';
import Weoffer from './Weoffer';
import WhoAreWe from './WhoAreWe';
import OurProjects from './OurProjects';
import Services from './Services';
import Whatwedo from './Whatwedo';
import Whatwedo1 from './Whatwedo1';
import WhatClientSays from './WhatClientSays';
import Aboutourbus from './Aboutourbus';
import Ourstrory from './Ourstory';
import OurTeam from './OurTeam';
import Contact1 from './ContactUs1';
import Footer from './Footer';
import BookAnAppointment from './BookAnAppointment';
import VideoComponent from './videocomponent';
import Savingcalculator from './Savingcalculator';
import Faq from './Faq';

function MainPage(props) {
  useEffect(() => {
    if (!sessionStorage.getItem("No")) {
      props.setPopup(true)
    }
    sessionStorage.setItem("No", 1)
  }, [])
  return (
    <div className="MainPage">

      <NavBar setPopup={props.setPopup} />

      <Introduction />

      <VideoComponent />

      <Savingcalculator />
      
      {/* <Faq /> */}
      
      <Supercharge />

      <Weoffer />

      <WhoAreWe />

      <BookAnAppointment />

      {/* <Services />       */}

      {/* <Whatwedo1 /> */}

      <WhatClientSays />

      <OurProjects />

      {/* <Aboutourbus /> */}

      {/* <Ourstrory /> */}
      
      {/* <OurTeam /> */}

      {/* <Whatwedo/>  */}

      <Contact1>      </Contact1>

      <Footer>        </Footer>
    </div>
  );
}

export default MainPage;
