import React, { useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import "../styles/Faq.scss";
import Faqhome from "../assets/Images/faq-home.svg";

function Faq() {
    const [selected, setSelected] = useState(0);

    const toggleOpen = (index) => {
      if (selected === index) {
        setSelected(0);
      } else {
        setSelected(index);
      }
    };

    const faqs = [
      "What is Solace?",
      "Who are the people behind Solace?",
      "How does the Solace work?",
      "What makes Solace different?",
      "What is the Solace virtual property manager?",
      "How much does the service cost?",
      "Why is Solace different from any other agent?"
    ];

    const faqAnswers = [
      "Solace is a tech-led solution for property professionals that assists them in managing their property portfolios in a way that reduces workload, maintains control and visibility, all at a cost significantly below current market rates.",
      "The Solace virtual property manager service has been developed by a team of highly experienced property professionals with over 30 years of experience in the UK market. They understand the current gaps in the systems and processes and have developed this service as the future of property management.",
      "Through our exclusive software and back-end support team of highly experienced property professionals and administrators, we offer a tailored solution to clients that reduces risk and workload, provides full visibility in real-time, and keeps key decisions with the client at all times.",
      "The Virtual Property Manager Solution is exclusively and perfectly designed to manage many day-to- day tasks automatically, which massively increases the productivity of the team and is therefore significantly cheaper than traditional property management services.",
      "The Solace Virtual Property Manager is the gateway to our services, offering a dashboard reporting structure that gives full visibility and control to the client while the team works in the background. It is simple and intuitive to use and supports the client in managing their properties.",
      "With every package tailored to the individual client, the cost will vary but is based on a percentage of rental yield. The savings compared to traditional property management services can be as much as 75%",
      "Solace is unique as it not only offers the virtual property manager but also the back-end management support, providing the best of both worlds at a fraction of the cost of traditional property management."
    ];
    const iconStyle = {
      backgroundColor: 'white'}

    return (
      <div className='third'>
        <div className='end-home'>
          <img src={Faqhome} alt='home' />
        </div>
        <div className='last-card'>
          <div className='faq-card'>
            <h1>FAQ</h1>
            {faqs.map((faq, index) => (
              <div className='fa' key={index}>
                <div className='faq' onClick={() => toggleOpen(index)}>
                  <p className='question'>{faq}</p>
                  <span className='ans-btn'>
                    {selected === index ? <IoIosArrowUp size={25} style={iconStyle} /> : <IoIosArrowDown size={25}  style={iconStyle} />}
                  </span>
                </div>
                <div className={`answer ${selected === index ? 'show' : ''}`}>
                  <p>{faqAnswers[index]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

export default Faq;
