function Amendments() {
  return (
    <section className="amendements-section margin-bottom-vsmall">
      <h2 className="heading-secondary margin-bottom-vsmall">Amendments</h2>
      <p className="para margin-bottom-vsmall">
        We may change this Privacy Policy from time to time. We will notify you if we make a
        significant change to this Privacy Policy, by contacting you through the contact details you
        have provided to us and by publishing an updated version on our website.
      </p>
    </section>
  );
}

export default Amendments;
