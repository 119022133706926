import React, { useRef, useState } from 'react';
import '../styles/ContactUs1.scss';
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "../styles/MapLeaflet.css";
import "leaflet/dist/leaflet.css";
import L, { Popup } from "leaflet";
// import GetOutlook from './GetOutlook';
import ContactEmail from './ContactEmail';
import osm from "./osm-provider";


const ContactUs1 = () => {

    const [center, setCenter] = useState({ lat: 51.49424133478069, lng: -0.11482089999999999 });
    const ZOOM_LEVEL = 12;
    const mapRef = useRef();
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(new Date());
    const [accordiantitle, setAccordiantitle] = useState("1");

    const handleClick = (event) => {
        console.log(event);
        setAccordiantitle(event.currentTarget.id);
    };

    const markerIcon = new L.icon({
        iconUrl: require("../assets/Images/Marker.png"),
        iconSize: [35, 45],
    });
    

    return (
        <div className='Contact-Us'>
            <div className='services ' id="contact-us">
                <div className="container-fluid contactContainer1">
                    <div className='row'>
                        <div className='col-md-1'></div>
                        <div className='col-md-5 inner-container'>
                            <h1 className='ServicesTag1'> Contact Us</h1>
                            <p className='contact-details'><a href='mailto:info@solacemanagement.co.uk'>info@solacemanagement.co.uk</a></p>
                            <p className='contact-details phonenumbertel'><a href='tel:020 3355 5093'>020 3355 5093</a></p>
                            <p className='contact-details'> 6 Mitre Passage<br /> Greenwich<br /> London<br /> SE10 0ER</p>
                        </div>
                        <div className='col-md-5'>
                            <ContactEmail></ContactEmail>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs1;
