import '../styles/WhatClientSays.scss';
import Carousel from 'react-bootstrap/Carousel';
import starfill from "../assets/Images/star-fill.png";
import starempty from "../assets/Images/star-empty.png";
import { useEffect, useState } from 'react';

function WhatClientSays() {

  const [lgwidth, setlgwidth] = useState(0);
  const [Readmore1, setReadmore1] = useState(false);
  const [Readmore2, setReadmore2] = useState(false);
  const [Readmore3, setReadmore3] = useState(false);
  const [Readmore4, setReadmore4] = useState(false);
  const [Readmore5, setReadmore5] = useState(false);
  const [Readmore6, setReadmore6] = useState(false);
  const [Readmore7, setReadmore7] = useState(false);
  const [Readmore8, setReadmore8] = useState(false);
  const [Readmore9, setReadmore9] = useState(false);
  const [Readmore10, setReadmore10] = useState(false);
  const [Readmore11, setReadmore11] = useState(false);
  const [Readmore12, setReadmore12] = useState(false);

  const handlechange = () => {
    console.log(window.innerWidth)
    if (window.innerWidth < 1000 && window.innerWidth > 700) {
      setlgwidth(1)
    }
    else if (window.innerWidth <= 700 && window.innerWidth > 500) {
      setlgwidth(2)
    }
    else if (window.innerWidth < 500) {
      setlgwidth(3)
    }
    else {
      setlgwidth(0)
    }
  }

  const Readmore = (val, e) => {
    if (val == "readmore") {
      setReadmore1(!Readmore1)
      if (Readmore1) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }
    if (val == "readmore2") {
      setReadmore2(!Readmore2)
      if (Readmore2) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore2").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore2").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }

    if (val == "readmore3") {
      setReadmore3(!Readmore3)
      if (Readmore3) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore3").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore3").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }

    if (val == "readmore4") {
      setReadmore4(!Readmore4)
      if (Readmore4) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore4").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore4").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }

    if (val == "readmore5") {
      setReadmore5(!Readmore5)
      if (Readmore5) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore5").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore5").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }

    if (val == "readmore6") {
      setReadmore6(!Readmore6)
      if (Readmore6) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore6").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore6").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }

    if (val == "readmore7") {
      setReadmore7(!Readmore7)
      if (Readmore7) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore7").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore7").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }

    if (val == "readmore8") {
      setReadmore8(!Readmore8)
      if (Readmore8) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore8").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore8").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }

    if (val == "readmore9") {
      setReadmore9(!Readmore9)
      if (Readmore9) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore9").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore9").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }

    if (val == "readmore10") {
      setReadmore10(!Readmore10)
      if (Readmore10) {
        // e.target.style.height = "auto"
        const div = document.getElementById("readmore10").parentNode.parentNode.parentNode
        div.style.height = "320px"
      }
      else {
        // e.target.style.height = "280.93px"
        const div = document.getElementById("readmore10").parentNode.parentNode.parentNode
        div.style.height = "auto"
      }
    }
  }




  useEffect(() => {
    handlechange()
  }, [])

  window.addEventListener("resize", handlechange)

  return (
    <div className="WhatClientSays">
      <h1 className='header'> What Clients Say</h1>

      {lgwidth == 0 &&
        <>
          <Carousel>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Since signing up with Solace, our offices have been running smoother than ever before. The virtual back office has been a lifesaver, allowing us to focus on what we do best: <span id="readmore" className={`Readmore1 ${Readmore1 ? "hide" : "show"}`} onClick={e => Readmore("readmore", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore1 ? "show" : "hide"}`}>selling property. The added services they provide, such as handling rent payments and maintenance requests, have made our lives so much easier. Highly recommend! <span id="readmore" onClick={e => Readmore("readmore", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Euros Estate, Canary Wharf
                  </p>
                </div>

                <div className='col-lg-3 wcs-col WhatClientSaysCarousel '>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a private landlord, it can be tough to compete with the big players in the industry. But thanks to Solace, I’ve been able to level the playing field. Their services have given me both <span id="readmore2" className={`Readmore1 ${Readmore2 ? "hide" : "show"}`} onClick={e => Readmore("readmore2", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore2 ? "show" : "hide"}`}>the freedom and time to focus on my business, without the added overhead costs. <span id="readmore2" onClick={e => Readmore("readmore2", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Anastasia, Wimbledon
                  </p>
                </div>

                <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Switching to a virtual back office was a big decision for my business, but I am so glad I made the move. Solace has been a fantastic partner, taking over all of my administrative<span id="readmore3" className={`Readmore1 ${Readmore3 ? "hide" : "show"}`} onClick={e => Readmore("readmore3", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore3 ? "show" : "hide"}`}> tasks tenant requests.<span id="readmore3" onClick={e => Readmore("readmore3", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Raj, Walthamstow
                  </p>
                </div>
          
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>

              <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a busy estate agent, it's essential to have a team of professionals handling the administrative tasks. That's where Solace comes in. Their services have been fantastic<span id="readmore4" className={`Readmore1 ${Readmore4 ? "hide" : "show"}`} onClick={e => Readmore("readmore4", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore4 ? "show" : "hide"}`}> and allow us to focus on providing excellent service to our clients. Highly recommend their services to anyone in the industry.<span id="readmore4" onClick={e => Readmore("readmore4", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Century 21, Elephant and Castle
                  </p>
                </div>

                <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Solace has been a breath of fresh air for our business. We no longer have to worry about the tedious administrative tasks that used to bog us down. Plus, their customer service is top-notch. <span id="readmore5" className={`Readmore1 ${Readmore5 ? "hide" : "show"}`} onClick={e => Readmore("readmore5", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore5 ? "show" : "hide"}`}> Would definitely recommend!<span id="readmore5" onClick={e => Readmore("readmore5", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Stonehenge Management, East Ham
                  </p>
                </div>

                <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    We were hesitant to switch to a virtual back office, but Solace have gone above and beyond to meet our needs. Their team is professional, knowledgeable, and always available to help.
                  </p>
                  <p className='wcs-des-p2'>
                    - Tantum Property, Lambeth
                  </p>
                </div>
                
                
              </div>
            </Carousel.Item>
            {/* <Carousel.Item>

              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Switching to a virtual back office was a big decision for my business, but I am so glad I made the move. Solace has been a fantastic partner, taking over all of my administrative <span id="readmore8" className={`Readmore1 ${Readmore8 ? "hide" : "show"}`} onClick={e => Readmore("readmore8", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore8 ? "show" : "hide"}`}>tasks tenant requests.<span id="readmore8" onClick={e => Readmore("readmore8", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Raj, Walthamstow
                  </p>
                </div>
                <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a busy estate agent, it's essential to have a team of professionals handling the administrative tasks. That's where Solace comes in. Their services have been fantastic<span id="readmore9" className={`Readmore1 ${Readmore9 ? "hide" : "show"}`} onClick={e => Readmore("readmore9", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore9 ? "show" : "hide"}`}> and allow us to focus on providing excellent service to our clients. Highly recommend their services to anyone in the industry.<span id="readmore9" onClick={e => Readmore("readmore9", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Century 21, Elephant and Castle
                  </p>
                </div>
                <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Solace has been a breath of fresh air for our business. We no longer have to worry about the tedious administrative tasks that used to bog us down. Plus, their customer service is top-notch. <span id="readmore10" className={`Readmore1 ${Readmore10 ? "hide" : "show"}`} onClick={e => Readmore("readmore10", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore10 ? "show" : "hide"}`}> Would definitely recommend!<span id="readmore10" onClick={e => Readmore("readmore10", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Stonehenge Management, East Ham
                  </p>
                </div>
                <div className='col-lg-3 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    We were hesitant to switch to a virtual back office, but Solace have gone above and beyond to meet our needs. Their team is professional, knowledgeable, and always available to help.
                  </p>
                  <p className='wcs-des-p2'>
                    - Tantum Property, Lambeth
                  </p>
                </div>
              </div>
            </Carousel.Item> */}
          </Carousel>
        </>

      }
      {lgwidth == 1 &&
        <>
          <Carousel>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Since signing up with Solace, our offices have been running smoother than ever before. The virtual back office has been a lifesaver, allowing us to focus on what we do best: <span id="readmore" className={`Readmore1 ${Readmore1 ? "hide" : "show"}`} onClick={e => Readmore("readmore", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore1 ? "show" : "hide"}`}>selling property. The added services they provide, such as handling rent payments and maintenance requests, have made our lives so much easier. Highly recommend! <span id="readmore" onClick={e => Readmore("readmore", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Euros Estate, Canary Wharf
                  </p>
                </div>

                <div className='col-lg-4 wcs-col WhatClientSaysCarousel '>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a private landlord, it can be tough to compete with the big players in the industry. But thanks to Solace, I’ve been able to level the playing field. Their services have given me both <span id="readmore2" className={`Readmore1 ${Readmore2 ? "hide" : "show"}`} onClick={e => Readmore("readmore2", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore2 ? "show" : "hide"}`}>the freedom and time to focus on my business, without the added overhead costs. <span id="readmore2" onClick={e => Readmore("readmore2", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Anastasia, Wimbledon
                  </p>
                </div>

                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Switching to a virtual back office was a big decision for my business, but I am so glad I made the move. Solace has been a fantastic partner, taking over all of my administrative<span id="readmore3" className={`Readmore1 ${Readmore3 ? "hide" : "show"}`} onClick={e => Readmore("readmore3", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore3 ? "show" : "hide"}`}> tasks tenant requests.<span id="readmore3" onClick={e => Readmore("readmore3", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Raj, Walthamstow
                  </p>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a busy estate agent, it's essential to have a team of professionals handling the administrative tasks. That's where Solace comes in. Their services have been fantastic<span id="readmore4" className={`Readmore1 ${Readmore4 ? "hide" : "show"}`} onClick={e => Readmore("readmore4", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore4 ? "show" : "hide"}`}> and allow us to focus on providing excellent service to our clients. Highly recommend their services to anyone in the industry.<span id="readmore4" onClick={e => Readmore("readmore4", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Century 21, Elephant and Castle
                  </p>
                </div>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Solace has been a breath of fresh air for our business. We no longer have to worry about the tedious administrative tasks that used to bog us down. Plus, their customer service is top-notch. <span id="readmore5" className={`Readmore1 ${Readmore5 ? "hide" : "show"}`} onClick={e => Readmore("readmore5", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore5 ? "show" : "hide"}`}> Would definitely recommend!<span id="readmore5" onClick={e => Readmore("readmore5", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Stonehenge Management, East Ham
                  </p>
                </div>

                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    We were hesitant to switch to a virtual back office, but Solace have gone above and beyond to meet our needs. Their team is professional, knowledgeable, and always available to help.
                  </p>
                  <p className='wcs-des-p2'>
                    - Tantum Property, Lambeth
                  </p>
                </div>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item>

              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Since signing up with Solace, our offices have been running smoother than ever before. The virtual back office has been a lifesaver, allowing us to focus on what we do best: <span id="readmore6" className={`Readmore1 ${Readmore6 ? "hide" : "show"}`} onClick={e => Readmore("readmore6", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore6 ? "show" : "hide"}`}>selling property. The added services they provide, such as handling rent payments and maintenance requests, have made our lives so much easier. Highly recommend!<span id="readmore6" onClick={e => Readmore("readmore6", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Euros Estate, Canary Wharf
                  </p>
                </div>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a private landlord, it can be tough to compete with the big players in the industry. But thanks to Solace, I’ve been able to level the playing field. Their services have given me both <span id="readmore7" className={`Readmore1 ${Readmore7 ? "hide" : "show"}`} onClick={e => Readmore("readmore7", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore7 ? "show" : "hide"}`}>the freedom and time to focus on my business, without the added overhead costs. <span id="readmore7" onClick={e => Readmore("readmore7", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Anastasia, Wimbledon
                  </p>
                </div>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Switching to a virtual back office was a big decision for my business, but I am so glad I made the move. Solace has been a fantastic partner, taking over all of my administrative<span id="readmore8" className={`Readmore1 ${Readmore8 ? "hide" : "show"}`} onClick={e => Readmore("readmore8", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore8 ? "show" : "hide"}`}> tasks tenant requests.<span id="readmore8" onClick={e => Readmore("readmore8", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Raj, Walthamstow
                  </p>
                </div>
              </div>
            </Carousel.Item> */}
            {/* <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a busy estate agent, it's essential to have a team of professionals handling the administrative tasks. That's where Solace comes in. Their services have been fantastic<span id="readmore9" className={`Readmore1 ${Readmore9 ? "hide" : "show"}`} onClick={e => Readmore("readmore9", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore9 ? "show" : "hide"}`}> and allow us to focus on providing excellent service to our clients. Highly recommend their services to anyone in the industry.<span id="readmore9" onClick={e => Readmore("readmore9", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Century 21, Elephant and Castle
                  </p>
                </div>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Solace has been a breath of fresh air for our business. We no longer have to worry about the tedious administrative tasks that used to bog us down. Plus, their customer service is top-notch. <span id="readmore10" className={`Readmore1 ${Readmore10 ? "hide" : "show"}`} onClick={e => Readmore("readmore10", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore10 ? "show" : "hide"}`}> Would definitely recommend!<span id="readmore10" onClick={e => Readmore("readmore10", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Stonehenge Management, East Ham
                  </p>
                </div>
                <div className='col-lg-4 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    We were hesitant to switch to a virtual back office, but Solace have gone above and beyond to meet our needs. Their team is professional, knowledgeable, and always available to help.
                  </p>
                  <p className='wcs-des-p2'>
                    - Tantum Property, Lambeth
                  </p>
                </div>

              </div>
            </Carousel.Item> */}
          </Carousel>
        </>

      }
      {lgwidth == 2 &&
        <>
          <Carousel>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Since signing up with Solace, our offices have been running smoother than ever before. The virtual back office has been a lifesaver, allowing us to focus on what we do best: <span id="readmore" className={`Readmore1 ${Readmore1 ? "hide" : "show"}`} onClick={e => Readmore("readmore", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore1 ? "show" : "hide"}`}>selling property. The added services they provide, such as handling rent payments and maintenance requests, have made our lives so much easier. Highly recommend! <span id="readmore" onClick={e => Readmore("readmore", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Euros Estate, Canary Wharf
                  </p>
                </div>

                <div className='col-lg-6 wcs-col WhatClientSaysCarousel '>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a private landlord, it can be tough to compete with the big players in the industry. But thanks to Solace, I’ve been able to level the playing field. Their services have given me both <span id="readmore2" className={`Readmore1 ${Readmore2 ? "hide" : "show"}`} onClick={e => Readmore("readmore2", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore2 ? "show" : "hide"}`}>the freedom and time to focus on my business, without the added overhead costs. <span id="readmore2" onClick={e => Readmore("readmore2", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Anastasia, Wimbledon
                  </p>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Switching to a virtual back office was a big decision for my business, but I am so glad I made the move. Solace has been a fantastic partner, taking over all of my administrative<span id="readmore3" className={`Readmore1 ${Readmore3 ? "hide" : "show"}`} onClick={e => Readmore("readmore3", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore3 ? "show" : "hide"}`}> tasks tenant requests.<span id="readmore3" onClick={e => Readmore("readmore3", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Raj, Walthamstow
                  </p>
                </div>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a busy estate agent, it's essential to have a team of professionals handling the administrative tasks. That's where Solace comes in. Their services have been fantastic<span id="readmore4" className={`Readmore1 ${Readmore4 ? "hide" : "show"}`} onClick={e => Readmore("readmore4", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore4 ? "show" : "hide"}`}> and allow us to focus on providing excellent service to our clients. Highly recommend their services to anyone in the industry.<span id="readmore4" onClick={e => Readmore("readmore4", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Century 21, Elephant and Castle
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Solace has been a breath of fresh air for our business. We no longer have to worry about the tedious administrative tasks that used to bog us down. Plus, their customer service is top-notch. <span id="readmore5" className={`Readmore1 ${Readmore5 ? "hide" : "show"}`} onClick={e => Readmore("readmore5", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore5 ? "show" : "hide"}`}> Would definitely recommend!<span id="readmore5" onClick={e => Readmore("readmore5", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Stonehenge Management, East Ham
                  </p>
                </div>

                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    We were hesitant to switch to a virtual back office, but Solace have gone above and beyond to meet our needs. Their team is professional, knowledgeable, and always available to help.
                  </p>
                  <p className='wcs-des-p2'>
                    - Tantum Property, Lambeth
                  </p>
                </div>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item>

              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Since signing up with Solace, our offices have been running smoother than ever before. The virtual back office has been a lifesaver, allowing us to focus on what we do best:<span id="readmore6" className={`Readmore1 ${Readmore6 ? "hide" : "show"}`} onClick={e => Readmore("readmore6", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore6 ? "show" : "hide"}`}>selling property. The added services they provide, such as handling rent payments and maintenance requests, have made our lives so much easier. Highly recommend! <span id="readmore6" onClick={e => Readmore("readmore6", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Euros Estate, Canary Wharf
                  </p>
                </div>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a private landlord, it can be tough to compete with the big players in the industry. But thanks to Solace, I’ve been able to level the playing field. Their services have given me both <span id="readmore7" className={`Readmore1 ${Readmore7 ? "hide" : "show"}`} onClick={e => Readmore("readmore7", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore7 ? "show" : "hide"}`}>the freedom and time to focus on my business, without the added overhead costs. <span id="readmore7" onClick={e => Readmore("readmore7", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Anastasia, Wimbledon
                  </p>
                </div>
              </div>
            </Carousel.Item> */}
            {/* <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Switching to a virtual back office was a big decision for my business, but I am so glad I made the move. Solace has been a fantastic partner, taking over all of my administrative<span id="readmore8" className={`Readmore1 ${Readmore8 ? "hide" : "show"}`} onClick={e => Readmore("readmore8", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore8 ? "show" : "hide"}`}> tasks tenant requests.<span id="readmore8" onClick={e => Readmore("readmore8", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Raj, Walthamstow
                  </p>
                </div>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a busy estate agent, it's essential to have a team of professionals handling the administrative tasks. That's where Solace comes in. Their services have been fantastic<span id="readmore9" className={`Readmore1 ${Readmore9 ? "hide" : "show"}`} onClick={e => Readmore("readmore9", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore9 ? "show" : "hide"}`}> and allow us to focus on providing excellent service to our clients. Highly recommend their services to anyone in the industry.<span id="readmore9" onClick={e => Readmore("readmore9", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Century 21, Elephant and Castle
                  </p>
                </div>
              </div>
            </Carousel.Item> */}
            {/* <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Solace has been a breath of fresh air for our business. We no longer have to worry about the tedious administrative tasks that used to bog us down. Plus, their customer service is top-notch. <span id="readmore10" className={`Readmore1 ${Readmore10 ? "hide" : "show"}`} onClick={e => Readmore("readmore10", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore10 ? "show" : "hide"}`}> Would definitely recommend!<span id="readmore10" onClick={e => Readmore("readmore10", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Stonehenge Management, East Ham
                  </p>
                </div>
                <div className='col-lg-6 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    We were hesitant to switch to a virtual back office, but Solace have gone above and beyond to meet our needs. Their team is professional, knowledgeable, and always available to help.
                  </p>
                  <p className='wcs-des-p2'>
                    - Tantum Property, Lambeth
                  </p>
                </div>
              </div>
            </Carousel.Item> */}
          </Carousel>
        </>

      }
      {lgwidth == 3 &&
        <>
          <Carousel>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Since signing up with Solace, our offices have been running smoother than ever before. The virtual back office has been a lifesaver, allowing us to focus on what we do best: <span id="readmore" className={`Readmore1 ${Readmore1 ? "hide" : "show"}`} onClick={e => Readmore("readmore", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore1 ? "show" : "hide"}`}>selling property. The added services they provide, such as handling rent payments and maintenance requests, have made our lives so much easier. Highly recommend! <span id="readmore" onClick={e => Readmore("readmore", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Euros Estate, Canary Wharf
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel '>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a private landlord, it can be tough to compete with the big players in the industry. But thanks to Solace, I’ve been able to level the playing field. Their services have given me both <span id="readmore2" className={`Readmore1 ${Readmore2 ? "hide" : "show"}`} onClick={e => Readmore("readmore2", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore2 ? "show" : "hide"}`}>the freedom and time to focus on my business, without the added overhead costs. <span id="readmore2" onClick={e => Readmore("readmore2", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Anastasia, Wimbledon
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Switching to a virtual back office was a big decision for my business, but I am so glad I made the move. Solace has been a fantastic partner, taking over all of my administrative<span id="readmore3" className={`Readmore1 ${Readmore3 ? "hide" : "show"}`} onClick={e => Readmore("readmore3", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore3 ? "show" : "hide"}`}> tasks tenant requests.<span id="readmore3" onClick={e => Readmore("readmore3", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Raj, Walthamstow
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a busy estate agent, it's essential to have a team of professionals handling the administrative tasks. That's where Solace comes in. Their services have been fantastic<span id="readmore4" className={`Readmore1 ${Readmore4 ? "hide" : "show"}`} onClick={e => Readmore("readmore4", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore4 ? "show" : "hide"}`}> and allow us to focus on providing excellent service to our clients. Highly recommend their services to anyone in the industry.<span id="readmore4" onClick={e => Readmore("readmore4", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Century 21, Elephant and Castle
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Solace has been a breath of fresh air for our business. We no longer have to worry about the tedious administrative tasks that used to bog us down. Plus, their customer service is top-notch. <span id="readmore5" className={`Readmore1 ${Readmore5 ? "hide" : "show"}`} onClick={e => Readmore("readmore5", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore5 ? "show" : "hide"}`}> Would definitely recommend!<span id="readmore5" onClick={e => Readmore("readmore5", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Stonehenge Management, East Ham
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    We were hesitant to switch to a virtual back office, but Solace have gone above and beyond to meet our needs. Their team is professional, knowledgeable, and always available to help.
                  </p>
                  <p className='wcs-des-p2'>
                    - Tantum Property, Lambeth
                  </p>
                </div>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item>

              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Since signing up with Solace, our offices have been running smoother than ever before. The virtual back office has been a lifesaver, allowing us to focus on what we do best:<span id="readmore6" className={`Readmore1 ${Readmore6 ? "hide" : "show"}`} onClick={e => Readmore("readmore6", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore6 ? "show" : "hide"}`}>selling property. The added services they provide, such as handling rent payments and maintenance requests, have made our lives so much easier. Highly recommend! <span id="readmore6" onClick={e => Readmore("readmore6", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Euros Estate, Canary Wharf
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a private landlord, it can be tough to compete with the big players in the industry. But thanks to Solace, I’ve been able to level the playing field. Their services have given me both <span id="readmore7" className={`Readmore1 ${Readmore7 ? "hide" : "show"}`} onClick={e => Readmore("readmore7", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore7 ? "show" : "hide"}`}>the freedom and time to focus on my business, without the added overhead costs. <span id="readmore7" onClick={e => Readmore("readmore7", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Anastasia, Wimbledon
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Switching to a virtual back office was a big decision for my business, but I am so glad I made the move. Solace has been a fantastic partner, taking over all of my administrative<span id="readmore8" className={`Readmore1 ${Readmore8 ? "hide" : "show"}`} onClick={e => Readmore("readmore8", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore8 ? "show" : "hide"}`}> tasks tenant requests.<span id="readmore8" onClick={e => Readmore("readmore8", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Raj, Walthamstow
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>As a busy estate agent, it's essential to have a team of professionals handling the administrative tasks. That's where Solace comes in. Their services have been fantastic<span id="readmore9" className={`Readmore1 ${Readmore9 ? "hide" : "show"}`} onClick={e => Readmore("readmore9", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore9 ? "show" : "hide"}`}> and allow us to focus on providing excellent service to our clients. Highly recommend their services to anyone in the industry.<span id="readmore9" onClick={e => Readmore("readmore9", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p23'>
                    - Century 21, Elephant and Castle
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    <span>Solace has been a breath of fresh air for our business. We no longer have to worry about the tedious administrative tasks that used to bog us down. Plus, their customer service is top-notch. <span id="readmore10" className={`Readmore1 ${Readmore10 ? "hide" : "show"}`} onClick={e => Readmore("readmore10", e)}> .... Read more</span></span><span className={`Readmoresub ${Readmore10 ? "show" : "hide"}`}> Would definitely recommend!<span id="readmore10" onClick={e => Readmore("readmore10", e)}>Read less</span></span>
                  </p>
                  <p className='wcs-des-p2'>
                    - Stonehenge Management, East Ham
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row WhatClientSaysCarousel-Main'>
                <div className='col-lg-12 wcs-col WhatClientSaysCarousel'>
                  <span className='star' >
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                    <img className='Starcss' src={starfill} />
                  </span>
                  <p className='wcs-des-p1'>
                    "We were hesitant to switch to a virtual back office, but Solace have gone above and beyond to meet our needs. Their team is professional, knowledgeable, and always available to help
                  </p>
                  <p className='wcs-des-p2'>
                    - Tantum Property, Lambet
                  </p>
                </div>
              </div>
            </Carousel.Item> */}
          </Carousel>
        </>
      }
    </div>
  );
}

export default WhatClientSays;
