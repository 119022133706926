import React from 'react';
import '../styles/Footer.css';
import fblogo from "../assets/Images/fblogo.png";
import instalogo from "../assets/Images/instalogo.png";
import logo from "../assets/Images/solace.png";
import linkedinlogo from "../assets/Images/linkedinlogo.png";
import pdf from '../assets/Images/privacydoc.pdf'

const Footer = () => {
    return (
        <div className='footer'>
            <hr className='horizontal-line'></hr>
            <div className='row rf1 footer-row container-fluid'>
                <div className='footer-logo'>
                    <img className="footer-logo-img" src={logo} />
                </div>
                <div className='col-md-4 footer-col-first'>
                    <div className='footer-text'>
                        <p >With decades of experience under one roof,<br />we know how property management works.<br />but  more importantly - we know how to<br />make it work better.</p>
                    </div>
                </div>
                <div className='col-md-4 col-sm-6 footer-col-second'>
                    <div className='footer-heading'>
                        SOLACE MANAGEMENT
                    </div>
                    <ul className='footer-ul'>
                        <li>
                            <a href='/'>Home</a>
                        </li>
                        <li>
                            <a href='/Services'>Services</a>
                        </li>
                        <li>
                            <a href='/Aboutus'>About Us</a>
                        </li>
                    </ul>
                </div>

                <div className='col-md-4 col-sm-6 footer-col-third'>
                    <div className='footer-heading'>
                        CONTACT US
                    </div>
                    <div className='footer-text1'>
                        <p ></p>
                        <a href="mailto:info@solacemanagement.co.uk" >info@solacemanagement.co.uk</a><br />
                        <p id="px1"><a href='tel:020 3355 5093'>020 3355 5093</a></p>
                        <p id="px2"> 6 Mitre Passage<br /> Greenwich<br /> London<br /> SE10 0ER</p>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className='footer-heading follow'>
                {/* <p></p> */}
                FOLLOW US
            </div>
            <div className='row follorow'>
                <div className='col-sm-6'>
                    <ul className="socials-icons" data-popuptext="Please bear with us, We will publish our status soon...">
                        <li className='facebook'>
                            <a target="_blank" rel="noopener" href='https://www.facebook.com/Solacemanagement.uk' style={{ background: "none" }}>
                                <img src={fblogo} style={{ width: "8.24px", height: "15.83px" }} />
                            </a>
                        </li>
                        <li className="linkedin">
                            <a target="_blank" rel="noopener" href='https://linkedin.com/company/solacemanagement' style={{ background: "none" }}>
                                <img src={linkedinlogo} style={{ width: "16.03px", height: "16.03px" }} />
                            </a>
                        </li>
                        <li className="instagram"><a target="_blank" rel="noopener" href=' https://www.instagram.com/solacemanagement.uk/' style={{ background: "none" }}>
                            <img src={instalogo} style={{ width: "15.97px", height: "15.97px" }} /></a>
                        </li>
                    </ul>
                </div>
                {/* <div className='col-sm-6 terms'> */}
                {/* <p>Sitemap  |  Terms & Conditions  |  Privacy Policy  |  Cookie Policy</p> */}
                {/* </div> */}
            </div>
            <hr className='horizontal-line2' />
            <div className='row'>
                <div className='col-md-12 copyright'>
                    <p style={{ width: "50%", float: "left" }}>
                        © Copyright 2022 Solace Management® All Rights Reserved.
                    </p>
                    <a style={{ float: "right", width: "40%", textAlign: "right", color: "blue", textDecoration: "underline", cursor: "pointer", marginRight: "17px" }} onClick={e => { window.location.href = "/privacypolicy" }}>Privacy Policy and Terms of Service</a>
                </div>
                {/* <div className='col-sm-6 copyright2'>
                    <p>
                        Made by Passionate Design Industry
                    </p>
                </div> */}
            </div>
        </div>
    );
};

export default Footer;
