function RightsAndControl() {
  return (
    <section className="rights-and-control-section margin-bottom-vsmall">
      <h2 className="heading-secondary margin-bottom-vsmall">
        Your rights and controlling your personal data:
      </h2>
      <p className="para margin-bottom-vsmall">
        <span className="highlight">Your choice: </span>Please read this Privacy Policy carefully.
        If you provide personal data to us, you understand we will collect, hold, use and disclose
        your personal data in accordance with this Privacy Policy. You do not have to provide
        personal data to us, however, if you do not, it may affect our ability to provide our
        Services to you and your use of our Services.
      </p>
      <p className="para margin-bottom-vsmall">
        <span className="highlight">Information from third parties: </span>
        If we receive personal data about you from a third party, we will protect it as set out in
        this Privacy Policy. If you are a third party providing personal data about somebody else,
        you represent and warrant that you have such person’s consent to provide the personal data
        to us.
      </p>
      <p className="para margin-bottom-vsmall">
        <span className="highlight">Access, correction, processing and portability: </span> You may
        request details of the personal data that we hold about you and how we process it (commonly
        known as a “data subject request”). You may also have a right in accordance with applicable
        data protection law to have your personal data rectified or deleted, to restrict our
        processing of that information, to object to decisions being made based on automated
        processing where the decision will produce a legal effect or a similarly significant effect
        on you, to stop unauthorised transfers of your personal data to a third party and, in some
        circumstances, to have personal data relating to you transferred to you or another
        organisation
      </p>
      <p className="para margin-bottom-vsmall">
        <span className="highlight">Unsubscribe: </span> To unsubscribe from our e-mail database or
        opt-out of communications (including marketing communications), please contact us using the
        details below or opt-out using the opt-out facilities provided in the communication
      </p>
      <p className="para margin-bottom-vsmall">
        <span className="highlight">Withdraw consent: </span>Where we are relying on consent to
        process your personal data, you have the right to withdraw your consent at any time.
        However, this will not affect the lawfulness of any processing carried out before you
        withdraw your consent. If you withdraw your consent, we may not be able to provide certain
        products or services to you. We will advise you if this is the case at the time you withdraw
        your consent.
      </p>
      <p className="para margin-bottom-vsmall">
        <span className="highlight">Complaints: </span>
        If you wish to make a complaint, please contact us using the details below and provide us
        with full details of the complaint. We will promptly investigate your complaint and respond
        to you, in writing, setting out the outcome of our investigation and the steps we will take
        to deal with your complaint. You have the right to make a complaint at any time to the
        Information Commissioner's Office (ICO), the UK regulator for data protection issues
        (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before
        you approach the ICO so please contact us in the first instance.
      </p>
    </section>
  );
}

export default RightsAndControl;
