
import "../styles/videocomponent.scss"
export default function VideoComponent() {
    return (
        // <div className="video_wrapper">
        //     {/* <iframe
        //         src="https://iframe.mediadelivery.net/play/250883/67f05d7a-cd0b-4c94-a849-87d38bb71633"
        //         // src="https://iframe.mediadelivery.net/embed/250883/67f05d7a-cd0b-4c94-a849-87d38bb71633?autoplay=true"
        //         title="videodescription" width={"100%"} height={800}> </iframe> */}
        // <div style={{ position: "relative", paddingTop: "56.25%" }}><iframe src="https://iframe.mediadelivery.net/embed/250883/67f05d7a-cd0b-4c94-a849-87d38bb71633?autoplay=true&loop=true&muted=true&preload=true&responsive=true" loading="lazy" style={{ border: 0, position: "absolute", top: 0, height: "100%", width: "100%" }} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe></div>
        // </div>
        <div className="video">

            <div className='row'>
                <div className='col-md-6 img-container'>
                    {/* <img className="img-fluid mx-auto d-block Whorwecss" src={homeimg3} /> */}
                    <div style={{ position: "relative", paddingTop: "56.25%" }}><iframe src="https://iframe.mediadelivery.net/embed/250883/67f05d7a-cd0b-4c94-a849-87d38bb71633?autoplay=true&loop=true&muted=true&preload=true&responsive=true" loading="lazy" style={{ border: 0, position: "absolute", top: 0, height: "100%", width: "100%" }} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe></div>

                </div>
                <div className='col-md-6 content-wrapper'>
                    <div>
                        <h1>Everything You Need<br></br> In ONE Place</h1>
                        <p>From seamless tenant communication and streamlined maintenance requests to detailed financial reporting and property insights, everything you need is here. Discover how our solution can revolutionize your property management, making it simpler, faster, and more effective.</p>
                        {/* <a href='/Aboutus' className='moreless-button'>Read More</a> */}
                        <button className="newbutton InputBox2" onClick={(e) => window.location.href = "/Contactus"}>Book a Demo</button>
                    </div>
                </div>
            </div>

            {/* <div className='row container-fluid WhoAreWe-Img-Row'>
                <div className='col-md-3 WhoAreWe-Img-Content'>
                    <img className="IconlogoImgcss1" src={IconlogoImg1} alt="IconlogoImg1"></img>
                    <p className='IconText'> More time for<br /> your business </p>
                </div>
                <div className='col-md-3 WhoAreWe-Img-Content'>
                    <img className="IconlogoImgcss2" src={IconlogoImg2} alt="IconlogoImg2"></img>
                    <p className='IconText'>Remove staff <br />dependency </p>
                </div>
                <div className='col-md-3 WhoAreWe-Img-Content'>
                    <img className="IconlogoImgcss3" src={IconlogoImg3} alt="IconlogoImg3"></img>
                    <p className='IconText'> Reduce costs <br />up to 50%</p>
                </div>
                <div className='col-md-3 WhoAreWe-Img-Content'>
                    <img className="IconlogoImgcss4 " src={IconlogoImg4} alt="IconlogoImg4"></img>
                    <p className='IconText '> Move your agency<br /> to the cloud</p>
                </div>
            </div> */}
        </div>
    )
}