function Cookies() {
  return (
    <section className="cookise-section margin-bottom-vsmall">
      <h2 className="heading-secondary margin-bottom-vsmall">Cookies:</h2>
      <p className="para margin-bottom-vsmall">
        We may use cookies on our website from time to time. Cookies are text files placed in your
        computer's browser to store your preferences. For more information about the cookies we use,
        please see our website{" "}
        <a href=" https://www.solacemanagement.co.uk/" className="breakable-link">
          https://www.solacemanagement.co.uk/
        </a>
      </p>
      <p className="para margin-bottom-vsmall">
        Our website may contain links to other party’s websites. We do not have any control over
        those websites and we are not responsible for the protection and privacy of any personal
        data which you provide whilst visiting those websites. Those websites are not governed by
        this Privacy Policy.
      </p>
    </section>
  );
}

export default Cookies;
