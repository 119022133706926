import "../styles/Whatwedo1.scss";
// import SolaceServiceslogo from "../assets/Images/SolaceServiceslogo.png";
import ServiceBanner from "../assets/Images/Ourstory.jpeg";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Whatwedo1 from "./../assets/Images/WhatWeDo1.png";
import Whatwedo2 from "./../assets/Images/WhatWeDo2.png";
import RightArrow from "../assets/Images/RightArrow.png";
import { useEffect, useState, useRef } from "react";
import $ from "jquery";

function Services() {
  //   const [scroll1, setscroll1] = useState(2280);
  //   const [scroll2, setscroll2] = useState(2380);
  //   const [scroll3, setscroll3] = useState(2490);
  //   const [scroll4, setscroll4] = useState(2620);
  const scrollToRef = (ref, scroll1) => window.scrollTo(0, scroll1);
  const myRef = useRef(null);
  const executeScroll = (scroll1) => scrollToRef(myRef, scroll1);

  const scrollToRef1 = (ref, scroll2) => window.scrollTo(0, scroll2);
  const myRef1 = useRef(null);
  const executeScroll1 = (scroll2) => scrollToRef1(myRef1, scroll2);

  const scrollToRef2 = (ref, scroll3) => window.scrollTo(0, scroll3);
  const myRef2 = useRef(null);
  const executeScroll2 = (scroll3) => scrollToRef2(myRef2, scroll3);

  const scrollToRef3 = (ref, scroll4) => window.scrollTo(0, scroll4);
  const myRef3 = useRef(null);
  const executeScroll3 = (scroll4) => scrollToRef3(myRef3, scroll4);

  //   const handlechange1 = () => {
  //     if (window.innerWidth < 500) {
  //       console.log(window.innerWidth)
  //       setscroll1(3000);
  //       setscroll2(3200);
  //       setscroll3(3400);
  //       setscroll4(3600);
  //     } else {
  //       setscroll1(1600);
  //       setscroll2(1900);
  //       setscroll3(2100);
  //       setscroll4(2170);
  //     }
  //   };

  //   window.addEventListener("resize", handlechange1);

  const Settittle = (val) => {
    const div = document.getElementsByClassName("accordion-collapse");
    // setAccordiantitle(val);
    console.log(div[0], div[1], "@@@@@@@@@@@@@");
    if (val == "1") {
      div[0].setAttribute("class", "accordion-collapse collapse show");
    }
    if (val == "2") {
      div[1].setAttribute("class", "accordion-collapse collapse show");
    }
    if (val == "3") {
      div[2].setAttribute("class", "accordion-collapse collapse show");
    }
    if (val == "4") {
      div[3].setAttribute("class", "accordion-collapse collapse show");
    }
  };

  // function CustomToggle1({ children, eventKey }) {
  //     const decoratedOnClick = useAccordionButton(eventKey, () => {
  //         if (accordiantitle == String(Number(eventKey) + 1)) {
  //             console.log(accordiantitle)
  //             setAccordiantitle("0");
  //         }
  //         else {
  //             setAccordiantitle(String(Number(eventKey) + 1));
  //         }
  //     }
  //     );

  useEffect(() => {
    // handlechange1();
    const v = window.location.href.split("#");
    // console.log(myRef.current.offsetTop)

    if (window.performance) {
      console.info("window.performance works fine on this browser");
      // alert("liii1")
    }
    console.info(performance.navigation.type);
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      // console.info( "This page is reloaded" );
      // alert("liii2")
      window.location.href = "/Services"
    } else {
      // console.info( "This page is not reloaded");
      // alert("liii1")
    }

    if (v.includes("1")) {
      setTimeout(() => {
        if (window.innerWidth < 500) {
          executeScroll(2700);
        }
        else {
          executeScroll(1600);
        }
        Settittle("1");
      }, 1000);
    }
    if (v.includes("2")) {
      setTimeout(() => {
        if (window.innerWidth < 500) {
          executeScroll1(2800);
        }
        else {
          executeScroll1(1900);
        }
        Settittle("2");
      }, 1000);
    }
    if (v.includes("3")) {
      setTimeout(() => {
        if (window.innerWidth < 500) {
          executeScroll2(2900);
        }
        else {
          executeScroll2(2100);
        }
        Settittle("3");
      }, 1000);
    }
    if (v.includes("4")) {
      setTimeout(() => {
        if (window.innerWidth < 500) {
          executeScroll3(3000);
        }
        else {
          executeScroll3(2170);
        }
        Settittle("4");
      }, 1000);
    }
  }, []);

  const [accordiantitle, setAccordiantitle] = useState("0");

  const handleClick = (event) => {
    // console.log(event.currentTarget.id);
    // executeScroll();
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (accordiantitle == String(Number(eventKey) + 1)) {
        // const div = document.getElementsByClassName("accordion-collapse collapse show")
        // if (div[0]) {
        //   div[0].children[0].style.display = "none"
        //   setAccordiantitle("0");
        // }
        // // setTimeout(() => {
        // //   const div = document.getElementsByClassName("accordion-collapse collapse show")
        // //   if (div[0]) {
        // //     div[0].setAttribute("class", "accordion-collapse collapse")
        // //     setAccordiantitle("0");
        // //   }
        // // }, 500)
        // console.log(accordiantitle);
        setAccordiantitle("0");
      } else {
      
        const div = document.getElementsByClassName("accordion-collapse collapse show")
        if (div[0]) {
          // console.log(div[0].children[0],"bbbbbbbbbbbbbb")
          div[0].setAttribute("class", "accordion-collapse collapse")
        }
        // if (div[1]) {
        //   div[1].children[0].style.display="block"
        // }
        // if (div[2]) {
        //   div[2].children[0].style.display="block"
        // }
        // if (div[3]) {
        //   div[3].children[0].style.display="block"
        // }
        const v1=window.location.href.split("#")
        if(v1.length == 1){
          setAccordiantitle(String(Number(eventKey) + 1));
        }
        else{
          setAccordiantitle("0")
        }
      }
    });

    return (
      <CardHeader type="CardHeader" onClick={decoratedOnClick}>
        {children}
      </CardHeader>
    );
  }

  return (
    <div id="WhatWeDoCont" className="Whatwedo">
      <div className="container-fluid wholecontainer">
        <div className="row">
          <div className="col-md-8 inner-wrapper">
            <h1 className="ServicesTagServices">What we do</h1>
            <Accordion className="accordion1">
              {/* Card 0 */}

              <Card>
                <Card.Header>
                  <CustomToggle eventKey="0">
                    {/* <img className=" img-fluid mx-auto RightArrowcss" src={RightArrow}></img> */}
                    <button
                      href="#p1"
                      key={1}
                      className={
                        accordiantitle === "1" ? "accordiantitle" : "nonColor"
                      }
                      id={"1"}
                      ref={myRef}
                      onClick={handleClick}
                    >
                      <span className="arrowSpan">→</span>Client Money
                    </button>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p className="paraColor">
                      Solace specialises in handling all necessary financial
                      tasks, including managing rent receipts, chasing arrears,
                      and organising outgoing payments to landlords. We ensure
                      that the exact amount payable to landlords is
                      automatically calculated and adjusted to include any
                      management fees.
                    </p>

                    <p className="paraColor">
                      With our comprehensive suite of accounting and banking
                      services, we ensure that your rental cash flows are
                      efficiently monitored and managed. The Solace portal
                      allows you to easily view all transactional information,
                      including rent payments and landlord statements. Outgoing
                      payments are authorised with just one click.
                    </p>

                    <p className="paraColor">
                      You will receive real-time updates and notifications, as
                      well as rental payment reminders sent to tenants prior to
                      the due date. Save time and money by entrusting us with
                      the management of your client money.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <br></br>

              {/* Card 1 */}

              <hr className="hrfaq1"></hr>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="1">
                    {/* <img className=" img-fluid mx-auto RightArrowcss" src={RightArrow}></img> */}
                    <button
                      href="#p2"
                      key={2}
                      className={
                        accordiantitle === "2" ? "accordiantitle" : "nonColor"
                      }
                      id={"2"}
                      ref={myRef1}
                      onClick={handleClick}
                    >
                      <span className="arrowSpan">→</span>Maintenance
                    </button>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p className="paraColor">
                      Solace offers a convenient portal for tenants to log
                      maintenance and repair issues with our 24/7 customer
                      support team.
                    </p>

                    <p className="paraColor">
                      Once a request is logged, you'll have the option to choose
                      your preferred supplier or select from a list of quotes
                      that we present. Upon approval, your nominated contractor
                      will be automatically assigned to the task and payments
                      can be handled with your verified payment method. When
                      completed, you'll receive an invoice for easy payment and
                      amounts due to landlords will be adjusted accordingly.
                    </p>

                    <p className="paraColor">
                      Our system allows for one-click approval of work, making
                      the process quick and efficient. Plus with the help of the
                      Solace portal, you can view past, present, and future
                      maintenance logs for easy tracking. And with no direct
                      contact with tenants required, our team can handle all
                      queries and ensure smooth communication.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <br></br>

              {/* Card 2 */}

              <hr className="hrfaq1"></hr>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="2">
                    {/* <img className=" img-fluid mx-auto RightArrowcss" src={RightArrow}></img> */}
                    <button
                      href="#p3"
                      key={3}
                      className={
                        accordiantitle === "3" ? "accordiantitle" : "nonColor"
                      }
                      id={"3"}
                      ref={myRef2}
                      onClick={handleClick}
                    >
                      <span className="arrowSpan">→</span>Compliance
                    </button>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <p className="paraColor">
                      At Solace, we provide expert support to ensure all
                      necessary legal checks and tests are up to date such as
                      EPC, EICR, PAT, and Gas Safe checks.
                    </p>

                    <p className="paraColor">
                      Our team of specialists are dedicated to keeping you on
                      the right side of the law, ensuring your tenants are safe.
                      We arrange for the specialists to visit the property to
                      certify compliance.
                    </p>

                    <p className="paraColor">
                      All parties are notified in advance and upon completion.
                      With our services, you can trust that landlords, estate
                      agents and tenants are always legally protected.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <br></br>

              {/* Card 3 */}

              <hr className="hrfaq1"></hr>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="3">
                    {/* <img className=" img-fluid mx-auto RightArrowcss" src={RightArrow}></img> */}
                    <button
                      href="#p4"
                      key={4}
                      className={
                        accordiantitle === "4" ? "accordiantitle" : "nonColor"
                      }
                      id={"4"}
                      ref={myRef3}
                      onClick={handleClick}
                    >
                      <span className="arrowSpan">→</span>
                      <span>Periodic Inspections</span>
                    </button>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <p className="paraColor">
                      Our team conduct periodic inspections to give you valuable
                      feedback on the condition of the property and how the
                      tenants have been maintaining it.
                    </p>

                    <p className="paraColor">
                      These inspections are an essential part of ensuring the
                      property is in good condition and that no untoward damages
                      have occurred. It is the perfect opportunity to uncover
                      and repair any minor problems before they become major.
                    </p>

                    <p className="paraColor">
                      As a client, you can choose how often these inspections
                      are carried out. And with the Solace portal, you can
                      easily view all reports digitally and stay up-to-date on
                      any issues that are flagged. By entrusting us with the
                      management of your periodic inspections, you can have
                      peace of mind knowing that your properties are being
                      properly cared for.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <br></br>
              <br></br>
            </Accordion>
          </div>
          <div className="col-md-4">
            <div className="ServicesParagraph">
              <div className="row topimg">
                <img src={Whatwedo1} className="img-fluid"></img>
              </div>
              <div className="row bottomimg">
                <img src={Whatwedo2} className="img-fluid"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
