import '../styles/OurProjects.scss';
import Carousel from 'react-bootstrap/Carousel';
import { useEffect, useState } from 'react';

import pimg1 from "../assets/Images/pimg1.png";
import pimg2 from "../assets/Images/pimg2.png";
import pimg3 from "../assets/Images/pimg3.png";
import timg1 from "../assets/Images/timg1.png";
import timg2 from "../assets/Images/timg2.png";
import timg3 from "../assets/Images/timg3.png";

function OurProjects() {


    const [lgwidthcar, setlgwidthcar] = useState(0);



    const handlechange = () => {
        console.log(window.innerWidth)
        if (window.innerWidth < 500) {
            setlgwidthcar(2)
        }
        else {
            setlgwidthcar(0)
        }
    }

    useEffect(() => {
        handlechange()
    }, [])


    return (
        <div className='container-fluid wholecontainer'>
            <div className="OurProjects">
                <h1>Our Projects</h1>
                {lgwidthcar == 0 &&
                    <div className='home-carousel'>
                        {/* <Carousel>
                      <Carousel.Item> */}
                        <div className='row carousel'>
                            <div className='col-md-4 carousel1-col'>
                                <img className="img-fluid mx-auto d-block image-project"
                                    src={pimg1} />
                                <h3 className='project-heading'>
                                    Corporate Identity Campaign
                                </h3>
                                <p>
                                    An Innovative Solution
                                </p>
                            </div>
                            <div className='col-md-4 carousel1-col C1I1'>
                                <img className="img-fluid mx-auto d-block image-project"
                                    src={pimg2} />
                                <h3 className='project-heading'>
                                    Brand Redesign
                                </h3>
                                <p>
                                    A Story Of Success
                                </p>
                            </div>

                            <div className='col-md-4 carousel1-col C1I1'>
                                <img className="img-fluid mx-auto d-block image-project"
                                    src={pimg3} />
                                <h3 className='project-heading'>
                                    Global Expansion
                                </h3>
                                <p>
                                    Turning New Page
                                </p>
                            </div>
                        </div>
                        {/* </Carousel.Item>
                      <Carousel.Item>
                          <div className='row carousel'>
                              <div className='col-md-4 carousel1-col C1I1'>
                                  <img className="img-fluid mx-auto d-block image-project"
                                      src={pimg1} />
                                  <h3 className='project-heading'>
                                      Corporate Identity Campaign
                                  </h3>
                                  <p>
                                      An Innovative Solution
                                  </p>
                              </div>
                              <div className='col-md-4 carousel1-col'>
                                  <img className="img-fluid mx-auto d-block image-project"
                                      src={pimg2} />
                                  <h3 className='project-heading'>
                                      Brand Redesign
                                  </h3>
                                  <p>
                                      A Story Of Success
                                  </p>
                              </div>
                              <div className='col-md-4 carousel1-col C1I1'>
                                  <img className="img-fluid mx-auto d-block image-project"
                                      src={pimg3} />
                                  <h3 className='project-heading'>
                                      Global Expansion
                                  </h3>
                                  <p>
                                      Turning New Page
                                  </p>
                              </div>
                          </div>
                      </Carousel.Item>
                      <Carousel.Item>
                          <div className='row carousel'>
                              <div className='col-md-4 carousel1-col C1I1'>
                                  <img className="img-fluid mx-auto d-block image-project"
                                      src={pimg1} />
                                  <h3 className='project-heading'>
                                      Corporate Identity Campaign
                                  </h3>
                                  <p>
                                      An Innovative Solution
                                  </p>
                              </div>
                              <div className='col-md-4 carousel1-col C1I1'>
                                  <img className="img-fluid mx-auto d-block image-project"
                                      src={pimg2} />
                                  <h3 className='project-heading'>
                                      Brand Redesign
                                  </h3>
                                  <p>
                                      A Story Of Success
                                  </p>
                              </div>
                              <div className='col-md-4 carousel1-col'>
                                  <img className="img-fluid mx-auto d-block image-project"
                                      src={pimg3} />
                                  <h3 className='project-heading'>
                                      Global Expansion
                                  </h3>
                                  <p>
                                      Turning New Page
                                  </p>
                              </div>
                          </div>
                      </Carousel.Item>
                  </Carousel> */}
                    </div>
                }
                {lgwidthcar == 2 &&

                    <div className='home-carousel'>
                        <Carousel>
                            <Carousel.Item>
                                <div className='row carousel'>
                                    <div className='col-md-4 carousel1-col'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg1} />
                                        <h3 className='project-heading'>
                                            Corporate Identity Campaign
                                        </h3>
                                        <p>
                                            An Innovative Solution
                                        </p>
                                    </div>
                                    <div className='col-md-4 carousel1-col C1I1'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg2} />
                                        <h3 className='project-heading'>
                                            Brand Redesign
                                        </h3>
                                        <p>
                                            A Story Of Success
                                        </p>
                                    </div>

                                    <div className='col-md-4 carousel1-col C1I1'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg3} />
                                        <h3 className='project-heading'>
                                            Global Expansion
                                        </h3>
                                        <p>
                                            Turning New Page
                                        </p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className='row carousel'>
                                    <div className='col-md-4 carousel1-col C1I1'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg1} />
                                        <h3 className='project-heading'>
                                            Corporate Identity Campaign
                                        </h3>
                                        <p>
                                            An Innovative Solution
                                        </p>
                                    </div>
                                    <div className='col-md-4 carousel1-col'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg2} />
                                        <h3 className='project-heading'>
                                            Brand Redesign
                                        </h3>
                                        <p>
                                            A Story Of Success
                                        </p>
                                    </div>
                                    <div className='col-md-4 carousel1-col C1I1'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg3} />
                                        <h3 className='project-heading'>
                                            Global Expansion
                                        </h3>
                                        <p>
                                            Turning New Page
                                        </p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className='row carousel'>
                                    <div className='col-md-4 carousel1-col C1I1'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg1} />
                                        <h3 className='project-heading'>
                                            Corporate Identity Campaign
                                        </h3>
                                        <p>
                                            An Innovative Solution
                                        </p>
                                    </div>
                                    <div className='col-md-4 carousel1-col C1I1'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg2} />
                                        <h3 className='project-heading'>
                                            Brand Redesign
                                        </h3>
                                        <p>
                                            A Story Of Success
                                        </p>
                                    </div>
                                    <div className='col-md-4 carousel1-col'>
                                        <img className="img-fluid mx-auto d-block image-project"
                                            src={pimg3} />
                                        <h3 className='project-heading'>
                                            Global Expansion
                                        </h3>
                                        <p>
                                            Turning New Page
                                        </p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>

                }
            </div>
        </div>
    );
}

export default OurProjects;
