import React, { useRef, useState } from 'react';
import '../styles/ContactUs.scss';
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "../styles/MapLeaflet.css";
import "leaflet/dist/leaflet.css";
import L, { Popup } from "leaflet";
// import GetOutlook from './GetOutlook';
import ContactEmail from './ContactEmail';
import osm from "./osm-provider";
import Faq from './Faq';


const ContactUs = () => {

    const [center, setCenter] = useState({ lat: 51.50089933308658,  lng: 0.0069266274654310065 });
    const ZOOM_LEVEL = 12;
    const mapRef = useRef();
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(new Date());
    const [accordiantitle, setAccordiantitle] = useState("1");

    const handleClick = (event) => {
        console.log(event);
        setAccordiantitle(event.currentTarget.id);
    };

    const markerIcon = new L.icon({
        iconUrl: require("../assets/Images/Marker.png"),
        iconSize: [35, 45],
    });
    

    return (
        <div className='Contact-Us'>
             <Faq />
            <div className='services ' id="contact-us">
                <div className="container-fluid contactContainer">
                    <div className='row'>
                        <div id="contact-us" className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                            <h1 className='ServicesTag'> Contact Us</h1>
                        </div>
                    </div>

                    <div className='row'>

                        <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7'>

                            <div className='row'>
                                <div className='col-md-6 openingtitle'>
                                    Opening Hours
                                    <p className="ContactAddress"> Mon - Fri: 9am - 6pm<br />
                                        {/* Sat: 10am - 2pm <br /> */}
                                        Sat - Sun: Closed</p>

                                </div>
                                <div className='col-md-6 openingtitlecol2'>
                                    Address
                                    <p className="ContactAddress"> 6 Mitre Passage<br/> Greenwich<br/> London<br/> SE10 0ER</p>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6 openingtitle'>
                                    Email
                                    <p className="ContactAddress1"> <a href='mailto:info@solacemanagement.co.uk'>info@solacemanagement.co.uk</a></p>
                                </div>

                                <div className='col-md-6 openingtitlecol2'>
                                    Phone
                                    <p className="ContactAddress"><a className="phonenumbertel" href='tel:020 3355 5093'>020 3355 5093</a></p>
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 openingtitle'>
                                    Get In Touch
                                </div>
                            </div>
                            <div className='OpeningHours'></div>
                            <ContactEmail />
                        </div>

                        <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 MapContainerCss'>

                            <MapContainer
                                center={center}
                                zoom={ZOOM_LEVEL}
                                ref={mapRef}
                                className="ContactMap"
                            >
                                <TileLayer url={osm.maptiler.url}
                                />

                                <Marker
                                    position={[51.50089933308658, 0.0069266274654310065]}
                                    icon={markerIcon}
                                    className="MarkerIconCss"
                                >
                                </Marker>
                            </MapContainer>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
