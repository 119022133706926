function CookieDescription() {
  return (
    <section className="cookie-description margin-bottom-vsmall">
      <h1 className="heading-primary text-center margin-bottom-vsmall margin-top-medium">
        SOLACE GROUP MANAGEMENT LTD - <br /> COOKIE POLICY
      </h1>
      <p className="para margin-bottom-vsmall">
        We may use cookies on our website (<span className="highlight">Services</span>) from time to
        time. In this Cookie Policy <span className="highlight">we</span>,
        <span className="highlight"> us</span> or <span className="highlight">our</span> means
        Solace Group Ltd., a company registered in England and Wales, with company number, 14545268.
      </p>
      <p className="para margin-bottom-vsmall">
        Cookies are text files placed in your computer's browser to store your preferences, provide
        a better user experience, and track patterns with regards to how users use our online
        Services. Cookies, by themselves, do not tell us your email address or other personally
        identifiable information. However, they do recognise you when you return to our online
        Services and allow third parties, such as Google and Facebook, to cause our advertisements
        to appear on your social media and online media feeds as part of our retargeting campaigns.
        If and when you choose to provide our online Services with personal information, this
        information may be linked to the data stored in the cookie
      </p>
      <p className="para margin-bottom-vsmall">We use the following cookies:</p>
      <ul className="inner-list">
        <li className="para">
          <span className="highlight"> Strictly necessary cookies.</span> These are cookies that are
          required for the operation of our online Services. They include, for example, cookies that
          enable you to log into secure areas of our online Services, use a shopping cart or make
          use of online payment services.
        </li>
        <li className="para">
          <span className="highlight"> Analytical/performance cookies. </span> These are cookies
          that allow us to recognise and count the number of visitors to our online Services and to
          see how visitors move around our online Services when they are using them. This helps us
          to improve the way our online Services work, for example, by ensuring that users find what
          they are looking for easily.
        </li>
        <li className="para">
          <span className="highlight"> Functionality cookies.</span> These are used to recognise you
          when you return to our online Services. These cookies enable us to personalise our content
          for you and remember your preferences (for example, your choice of language or region).
        </li>
        <li className="para">
          <span className="highlight"> Targeting and advertising cookies.</span>
          These cookies record your visit to our website, the pages you have visited and the links
          you have followed. We will use this information to make our website and the advertising
          displayed on it more relevant to your interests. [We may also share this information with
          third parties for this purpose.]
        </li>
        <li className="para">
          <span className="highlight"> Social media cookies.</span> These cookies are used when you
          share information using a social media sharing button or “like” button on our websites or
          you link your account or engage with our content on or through a social media site. The
          social network will record that you have done this. This information may be linked to
          targeting/advertising activities.
        </li>
      </ul>
    </section>
  );
}

export default CookieDescription;
