function ContactInformationCookie() {
  return (
    <section className="contact-information-section margin-bottom-medium">
      <h2 className="heading-secondary margin-bottom-vsmall">
        For any questions or notices, please contact us at:
      </h2>

      <p className="para margin-bottom-vsmall">
        Email:<a href="mailto:info@solacemanagement.co.uk">info@solacemanagement.co.uk</a>
      </p>
      <p className="para margin-bottom-vsmall">
        <span className="highlight">Last Update</span> 14 September 2023
      </p>
      <p className="para margin-bottom-vsmall">
        ©{" "}
        <a href="https://legalvision.co.uk/cookie-policy-uk/" className="breakable-link">
          LegalVision Law UK LTD
        </a>{" "}
        Law UK Ltd
      </p>
    </section>
  );
}

export default ContactInformationCookie;
