import React, { useRef, useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import "../styles/MainPage.scss";
import BookAnAppointment from "./BookAnAppointment";

function Fullcontactus(props) {
  return (
    <div className="MainPage">
      <NavBar setPopup={props.setPopup}></NavBar>
      <ContactUs></ContactUs>
      <BookAnAppointment></BookAnAppointment>
      <Footer></Footer>
    </div>
  );
}

export default Fullcontactus;
