import React, { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import "../styles/Calendy.css";



const Calendly1 = () => {

    // Calendly.initInlineWidget({
    //     url: 'https://calendly.com/YOURLINK',
    //     parentElement: document.getElementById('SAMPLEdivID'),
    //     prefill: {},
    //     utm: {}
    // });

    const [calenderurl, setcalenderurl]= useState('');

    useEffect(() => {
        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        console.log(day, month, year)

        const url='https://calendly.com/solacemanagement/30min?month='+String(year)+'-'+String(month)+'&diagnostics=1';

        setcalenderurl(url)
        console.log(url)
    }, [])

    return (

        <div class="calendly-inline-widget" data-auto-load="false">
            
            {calenderurl != "" && <InlineWidget url= {calenderurl} />}

        </div>

    );
};

export default Calendly1;