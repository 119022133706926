// import "../css/Policy.css";
import "../../styles/Policy.css";
import CookieDescription from "../PrivacyDocs/CookieDescription";

import TabularColumCookie from "../PrivacyDocs/TabularColumCookie";
import ContactInformationCookie from "../PrivacyDocs/ContactInformationCookie";
import NavBar from "../NavBar";
import Footer from "../Footer";
function CookiePolicy(props) {
  return (
    <section className="cookie-policy-section">
      <NavBar setPopup={props.setPopup} />
      <div className="container1">
        <CookieDescription />
        <TabularColumCookie />
        <ContactInformationCookie />
      </div>
      <Footer></Footer>
    </section>
  );
}

export default CookiePolicy;
