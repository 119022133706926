import "../styles/Weoffer.scss";
import listicon from "../assets/Images/listicon.png";
import { redirect } from "react-router-dom";

function Weoffer() {

  const redirection=(val)=>{
    if(val=="clientmoney"){
      //  console.log("@@@@@@@@@@@@@@@@@@@@")
       window.location.href="/Services/#1"
    }
    if(val=="maintenance"){
      //  console.log("@@@@@@@@@@@@@@@@@@@@")
       window.location.href="/Services/#2"
    }
    if(val=="compliance"){
      //  console.log("@@@@@@@@@@@@@@@@@@@@")
       window.location.href="/Services/#3"
    }
    if(val=="periodic"){
      //  console.log("@@@@@@@@@@@@@@@@@@@@")
       window.location.href="/Services/#4"
    }
  }

  

  return (
    <div className="Weoffer_outline container">
      <div className="row">
        <div className="col-xl-4 leftside-content-wrapper">
          <h1>We offer</h1>
          <p>
            Solace property management service replaces your physical property
            manager with a virtual one.
          </p>
        </div>
        <div className="col-xl-8">
          <div className="row">
            <div className="col-md-6">
              <div className="card inner-card" 
              onClick={e=> redirection("clientmoney")}
              >
                <div className="card-header inner-card-header">
                  <h6>Client Money</h6>
                </div>
                <div className="card-body inner-card-body">
                  <ul>
                    <li>
                      <div>Rental account monitoring</div>
                    </li>
                    <li>
                      <div>Requesting payments from tenants</div>
                    </li>
                    <li>
                      <div>Delivering landlord & supplier statements</div>
                    </li>
                    <li>
                      <div>Handling of all administrative tasks</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card inner-card"
              onClick={e=> redirection("maintenance")}
              >
                <div className="card-header inner-card-header">
                  <h6>Maintenance</h6>
                </div>
                <div className="card-body inner-card-body">
                  <ul>
                    <li>
                      <div>Tenants report via online portal</div>
                    </li>
                    <li>
                      <div>Choose and assign preferred contractor</div>
                    </li>
                    <li>
                      <div>Track maintenance requests</div>
                    </li>
                    <li>
                      <div>End-to-end management</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card inner-card"
              onClick={e=> redirection("compliance")}
              >
                <div className="card-header inner-card-header">
                  <h6>Compliance</h6>
                </div>
                <div className="card-body inner-card-body">
                  <ul>
                    <li>
                      <div>Ensuring UK letting requirements are met</div>
                    </li>
                    <li>
                      <div>Deposit protection & safety certificates arranged</div>
                    </li>
                    <li>
                      <div>Access to all documents online</div>
                    </li>
                    <li>
                      <div>Keeping your properties legal</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card inner-card"
              onClick={e=> redirection("periodic")}
              >
                <div className="card-header inner-card-header">
                  <h6>Periodic Inspections</h6>
                </div>
                <div className="card-body inner-card-body">
                  <ul>
                    <li>
                      <div>Scheduling periodic property assessments</div>
                    </li>
                    <li>
                      <div>Conducting thorough evaluations &amp; reports</div>
                    </li>
                    <li>
                      <div>Recommending repairs or maintenance</div>
                    </li>
                    <li>
                      <div>Hassle and headache free</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weoffer;
