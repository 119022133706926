function DataRetention() {
  return (
    <div className="data-retention-box margin-bottom-small">
      <h2 className="heading-secondary margin-bottom-vsmall">Data retention:</h2>
      <p className="para margin-bottom-vsmall">
        We will only retain your personal data for as long as reasonably necessary to fulfil the
        purposes we collected it for, including for the purposes of satisfying any legal,
        regulatory, tax, accounting or reporting requirements. We may retain your personal data for
        a longer period in the event of a complaint or if we reasonably believe there is a prospect
        of litigation in respect to our relationship with you
      </p>
      <p className="para margin-bottom-vsmall">
        To determine the appropriate retention period for personal data, we consider the amount,
        nature and sensitivity of the personal data, the potential risk of harm from unauthorised
        use or disclosure of your personal data, the purposes for which we process your personal
        data and whether we can achieve those purposes through other means, and the applicable
        legal, regulatory, tax, accounting or other requirements.
      </p>
    </div>
  );
}

export default DataRetention;
