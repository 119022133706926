function ContactInformation() {
  return (
    <section className="contact-information-section margin-bottom-medium">
      <h2 className="heading-secondary margin-bottom-vsmall">
        For any questions or notices, please contact us at:
      </h2>
      <p className=" para margin-bottom-vsmall">
        Solace Group Ltd, a company registered in England and Wales, with company number, 14545268.
      </p>
      <p className="para margin-bottom-vsmall">
        Email:
        <a href="mailto:info@solacemanagement.co.uk" className="breakable-link">
          info@solacemanagement.co.uk
        </a>
      </p>
      <p className="para margin-bottom-vsmall">
        <span className="highlight">Last Update</span> 14 September 2023
      </p>
      <p className="para margin-bottom-vsmall">
        © <a href="https://legalvision.co.uk/">LegalVision</a> Law UK Ltd
      </p>
    </section>
  );
}

export default ContactInformation;
