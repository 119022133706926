function OverseasTransfers() {
  return (
    <div className="overseas-transfers-box margin-bottom-vsmall">
      <h2 className="heading-secondary margin-bottom-vsmall">Overseas transfers:</h2>
      <p className="para margin-bottom-vsmall">
        Where we disclose personal data to the third parties listed above, these third parties may
        store, transfer or access personal data outside of the United Kingdom, including India. The
        level of data protection in countries outside of the United Kingdom may be less
        comprehensive than what is offered in the United Kingdom. Where we transfer your personal
        data outside of the United Kingdom, we will perform those transfers using appropriate
        safeguards in accordance with the requirements of applicable data protection laws and we
        will protect the transferred personal data in accordance with this Privacy Policy. This
        includes
      </p>
      <p className="para">This includes:</p>
      <ul className="inner-list">
        <li className="para">
          only transferring your personal data to countries that have been deemed by applicable data
          protection laws to provide an adequate level of protection for personal data; or
        </li>
        <li className="para">
          including standard contractual clauses in our agreements with third parties that are
          overseas.
        </li>
      </ul>
    </div>
  );
}

export default OverseasTransfers;
