import React, { useEffect, useRef, useState } from "react";
import "../styles/NavBar.scss";

// navbar
import Dropdown from "react-bootstrap/Dropdown";

// images
import logo from "../assets/Images/solace.png";
// import downarrow from "../assets/Images/downarrow.png";
import Navlist from "./Navlist";

// icons
import { BiSearch } from "react-icons/bi";
import { CgMenu } from "react-icons/cg";

function NavBar(props) {
  const [open, setOpen] = useState(false);

  const [service, setservice] = useState(false);
  const [About, setabout] = useState(false);
  const [contact, setcontact] = useState(false);
  const [login, setLogin] = useState(false);
  const [clientLogin, setClientlogin] = useState(false);
  const [tenentLogin, setTenentLogin] = useState(false);

  useEffect(() => {
    const v = window.location.href.split("/");
    console.log(v[v.length - 1]);
    if (v.includes("Aboutus")) {
      setservice(false);
      setabout(true);
      setcontact(false);
      setLogin(false);
      setTenentLogin(false);
    }
    if (v.includes("Services")) {
      setservice(true);
      setabout(false);
      setcontact(false);
      setLogin(false);
      setTenentLogin(false);
    }
    if (v.includes("Contactus")) {
      setservice(false);
      setabout(false);
      setcontact(true);
      setLogin(false);
      setTenentLogin(false);
    }
    if (v.includes("Login")) {
      setservice(false);
      setabout(false);
      setcontact(false);
      setLogin(true);
    }
    // else{
    //    window.location.href="/"
    // }
  }, []);

  const Serviceclick = (e) => {
    setservice(true);
    setabout(false);
    setcontact(false);
    setLogin(false);
    setClientlogin(false);
  };

  const Aboutclick = (e) => {
    // debugger
  };

  const Contactclick = (e) => {
    setservice(false);
    setabout(false);
    setcontact(true);
    setLogin(false);
    setClientlogin(false);
  };

  const Loginclick = (e) => {
    setservice(false);
    setabout(false);
    setcontact(false);
    setLogin(true);
    setClientlogin(false);
  };
  const clientclick = (e) => {
    setservice(false);
    setabout(false);
    setcontact(false);
    setLogin(false);
    setClientlogin(true);
  };

  return (
    <div className="NavBar_Main">
      <div className="mainnavbar container-fluid">
        <a href="/">
          <img className="mainlogo" src={logo} alt="logo" />
        </a>

        <ul className="nav">
          <li className={`nav-item ni ${service ? "navactive" : ""}`}>
            <a
              className="nav-link"
              onClick={(e) => Serviceclick(e)}
              href="/Services"
              id="Servicesid"
            >
              {" "}
              Services
            </a>
          </li>
          <li className={`nav-item ni ${About ? "navactive" : ""}`}>
            <a
              className="nav-link"
              href="/Aboutus"
              onClick={(e) => Aboutclick(e)}
              id="Aboutusid"
            >
              About Us
            </a>
          </li>
          <li className={`nav-item ni ${contact ? "navactive" : ""}`}>
            <a
              className="nav-link"
              href="/Contactus"
              onClick={(e) => Contactclick(e)}
              id="Contactus"
            >
              Contact
            </a>
          </li>
          {/* <li className={`nav-item ni ${""}`} style={{cursor:"pointer"}}> */}
          {/* <img className="nav-link search" src={searchlogo} /> */}
          {/* <a className='nav-link' onClick={e=>{props.setPopup(true)}}>Sign Up</a> */}
          {/* </li> */}
          {/* <select className={`nav-item ni ${About ? "navactive" : ""}`}style={{border:"none",fontSize:"20px"}}>
                        
                        <option><a href='/Login'>Admin Login</a></option>
                        <option>Client Login</option>
                    </select>
                    <div class="dropdown">
  <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown link
  </a>

  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>
  </ul>
  </div> */}
          <button
            className={`loginButton nav-item ${login ? "navactive" : ""}`}
            onClick={() => {
              window.open(
                //" https://app.solacemanagement.co.uk/"
                "https://tenant.solacemanagement.co.uk/",
                "_blank"
              );
            }}
          >
            <a
              href="https://tenant.solacemanagement.co.uk/" // "https://app.solacemanagement.co.uk/"
              target="_blank"
            >
              Tenant Login
            </a>
          </button>
          <button
            className={`loginButton nav-item ${login ? "navactive" : ""}`}
            onClick={() => {
              window.open(
                //" https://app.solacemanagement.co.uk/"
                // "https://devlandlord.solacemanagement.co.uk/",
                "https://landlord.solacemanagement.co.uk/",

                "_blank"
              );
            }}
          >
            <a
              href="https://landlord.solacemanagement.co.uk/"
              // "https://devlandlord.solacemanagement.co.uk/" // "https://app.solacemanagement.co.uk/"
              target="_blank"
            >
              Landlord Login
            </a>
          </button>
          <button
            className={`loginButton nav-item ${clientLogin ? "navactive" : ""}`}
            onClick={() => {
              window.open(
                "https://client.solacemanagement.co.uk/",
                // "https://devclient.solacemanagement.co.uk/",
                "_blank"
              );
            }}
          >
            <a
              href="https://client.solacemanagement.co.uk/" // href="https://devclient.solacemanagement.co.uk/" //
              target="_blank"
            >
              Client Login
            </a>
          </button>
          <button
            className={`loginButton nav-item ${login ? "navactive" : ""}`}
            onClick={() => {
              window.open(
                " https://app.solacemanagement.co.uk/",
                // "https://devapp.solacemanagement.co.uk/",
                "_blank"
              );
            }}
          >
            <a
              href="https://app.solacemanagement.co.uk/" // "https://devapp.solacemanagement.co.uk/"
              target="_blank"
            >
              Admin Login
            </a>
          </button>
        </ul>
        <div className="HamMenu">
          <CgMenu
            className="Hamburger"
            size="40px"
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>

      <div className="HamItems">{open && <Navlist />}</div>
    </div>
  );
}

export default NavBar;
