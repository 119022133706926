function StorageAndSecurity() {
  return (
    <section className="storage-rights-section margin-bottom-vsmall">
      <h2 className="heading-secondary margin-bottom-vsmall">Storage and security:</h2>
      <p className="para margin-bottom-vsmall">
        We are committed to ensuring that the personal data we collect is secure. In order to
        prevent unauthorised access or disclosure, we have put in place suitable physical,
        electronic and managerial procedures, to safeguard and secure personal data and protect it
        from misuse, interference, loss and unauthorised access, modification and disclosure
      </p>
      <p className="para margin-bottom-vsmall">
        While we are committed to security, we cannot guarantee the security of any information that
        is transmitted to or by us over the Internet. The transmission and exchange of information
        is carried out at your own risk.
      </p>
    </section>
  );
}

export default StorageAndSecurity;
