function Disclosures() {
  return (
    <section className="section">
      <div className="disclosure-box">
        <h2 className="heading-secondary margin-bottom-vsmall">
          Our disclosures of personal data to third parties:
        </h2>
        <p className="para margin-bottom-vsmall">We may disclose personal data to:</p>
        <ul className="inner-list margin-bottom-vsmall">
          <li className="para">Our employees, contractors, and/or related entities;</li>
          <li className="para">
            IT service providers, data storage, web-hosting, and server providers such as Microsoft
            Azure and ClickUp;
          </li>
          <li className="para">Marketing or advertising providers;</li>
          <li className="para">
            Professional advisors, bankers, auditors, our insurers, and insurance brokers;
          </li>
          <li className="para">Payment systems operators such as Stripe & PayPal;</li>
          <li className="para">Our existing or potential agents or business partners;</li>
          <li className="para">Sponsors or promoters of any promotions or competition we run;</li>
          <li className="para">
            Anyone to whom our business or assets (or any part of them) are, or may (in good faith)
            be, transferred;
          </li>
          <li className="para">
            Courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or
            services we have provided to you;
          </li>
          <li className="para">
            Courts, tribunals, regulatory authorities, and law enforcement officers, as required or
            authorized by law, in connection with any actual or prospective legal proceedings, or to
            establish, exercise, or defend our legal rights;
          </li>
          <li className="para">
            Third parties to collect and process data, such as Google Analytics (To find out how
            Google uses data when you use third party websites or applications, please see 
            <a href="https://www.google.com/policies/privacy/partners/ " className="breakable-link">
               &nbsp;www.google.com/policies/privacy/partners/
            </a>
            or any other URL Google may use from time to time), Facebook Pixel, or other relevant
            analytics businesses;
          </li>
          <li className="para">
            Any other third parties as required or permitted by law, such as where we receive a
            summons.
          </li>
        </ul>
        <p className="para margin-bottom-vsmall">
          <span className="highlight">Google Analytics:</span>We have enabled Google Analytics
          Advertising Features including Remarketing Features, Advertising Reporting Features,
          Demographics and Interest Reports, Store Visits, and Google Display Network Impression
          reporting. We and third-party vendors use first-party cookies (such as the Google
          Analytics cookie) or other first-party identifiers, and third-party cookies (such as
          Google advertising cookies) or other third-party identifiers together.
        </p>
        <p className="para margin-bottom-vsmall">
          You can opt-out of Google Analytics Advertising Features including using a Google
          Analytics Opt-out Browser add-on found{" "}
          <a href="https://tools.google.com/dlpage/gaoptout/">here</a>
        </p>
        <p className="para margin-bottom-vsmall">
          To opt-out of personalised ad delivery on the Google content network, please visit
          Google’s Ads Preferences Manager{" "}
          <a href="https://support.google.com/My-Ad-Center-Help/answer/12155656?visit_id=638351552097100312-3193456919&rd=1">
            here
          </a>{" "}
          or if you wish to opt-out permanently even when all cookies are deleted from your browser
          you can install their plugin{" "}
          <a href="https://support.google.com/My-Ad-Center-Help/answer/12155656?visit_id=638351552098018368-2599191216&rd=1">
            here
          </a>{" "}
          . To opt out of interest-based ads on mobile devices, please follow these instructions for
          your mobile device: On android open the Google Settings app on your device and select
          “ads” to control the settings. On iOS devices with iOS 6 and above use Apple’s advertising
          identifier. To learn more about limiting ad tracking using this identifier, visit the
          settings menu on your device.
        </p>
      </div>
    </section>
  );
}

export default Disclosures;
