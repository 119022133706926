import '../styles/Aboutourbus.scss';
// import SolaceServiceslogo from "../assets/Images/SolaceServiceslogo.png";
import ServiceBanner from "../assets/Images/Aboutourbus.jpeg";


function Services() {
    return (
        <div className="Aboutus">

            <div className='Services-img'>
                <img src={ServiceBanner} className='img-fuild'></img>
            </div>
            <div className='container-fluid wholecontainer'>
                <div className='row'>
                    <div className='col-md-6 inner-wrapper'>
                        <h1 id='AboutOurBus' className='ServicesTagServices'>About Our Business</h1>
                        {/* <div className='inner-image'>
                            <img src={SolaceServiceslogo} alt="SolaceServiceslogo" className='img-fluid  d-block SolaceServiceslogo'></img>
                        </div> */}
                    </div>
                    <div className='col-md-6'>
                        <div className='ServicesParagraph'>
                            <p id="para1">At Solace, we understand that change is not always easy. Since 2000, we’ve been helping property owners and agents of all sizes respond to industry transitions in order to stay competitive. Our years of experience have taught us to always make your business success our priority.
                            </p>
                            <p>
                                Our team of experts is ready to help you develop strategies for not only surviving, but thriving in the future. Give us a call today and take a step into the modern world of property management.
                            </p>
                            <p>
                                For the past twenty years, we’ve been helping property owners and estate agents navigate the market, respond to change, and transition to better ways of working.
                            </p>
                            <p>
                                During this time, we’ve seen it all. The highs. The lows. The good. The bad. It’s this experience that led us to a realisation: there’s a better way to manage properties.</p>
                            <p>That’s when Solace was born. We’re the changemakers of the industry. The modernisers. The pathfinders. And we’re here to help you make that transition.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Services;
