import '../styles/OurTeam.scss';
// import SolaceServiceslogo from "../assets/Images/SolaceServiceslogo.png";
import ServiceBanner from "../assets/Images/Ourstory.jpeg";
import Dawn from "../assets/Images/OurTeam/dawn_pic_1.jpg";
import Edward from "../assets/Images/OurTeam/edward_pic_1.jpg";
import Ejas from "../assets/Images/ourTeam2/Ejas_Pic.png";
import Eugenija from "../assets/Images/OurTeam/eugenija_pic_1.jpg";
import Natalie from "../assets/Images/OurTeam/natalie_pic_1.jpg";
import Oleg from "../assets/Images/OurTeam/oleg_pic_1.jpg";
import Pradip from "../assets/Images/OurTeam/pradip_pic_1.jpg";
import Sergi from "../assets/Images/OurTeam/sergi_pic_1.jpg";
import Siraj from "../assets/Images/OurTeam/siraj_pic.jpg";
import Thameem from "../assets/Images/ourTeam2/thameem_pic.png";
import Yasid from "../assets/Images/ourTeam2/yasid_pic_1.png";
import Mark from "../assets/Images/ourTeam3/Mark Thorpe - General Manager.png";
import Madana from "../assets/Images/ourTeam3/Madana Jahir - Accounts.png" 
import Prathiba from "../assets/Images/ourTeam3/Prathiba - Property.png" 
import Syed  from "../assets/Images/ourTeam3/Syed - Property.png" 
import Abdul from "../assets/Images/ourTeam3/Abdul Azzez - Digital Marketing.png" 
import Felix from "../assets/Images/ourTeam3/Felix - Property.png" 
import Sophia from "../assets/Images/ourTeam3/Sophia Bukhari - BD Solace.png" 







function Services() {
    const Team = [
        {
            Pic: Siraj,
            Name: "Siraj Deane",
            Subname: "Senior Partner"
        },
        {
            Pic: Mark,
            Name: "Mark Thorpe",
            Subname: "General Manager"
        },
        {
            Pic: Yasid,
            Name: "Yazid Yasin",
            Subname: "Operations Manager"
        },
        {
            Pic: Ejas,
            Name: "Ejas Deane",
            Subname: "Sales & Marketing Manager"
        },
        {
            Pic: Natalie,
            Name: "Natalie Taylor",
            Subname: "Client Relationship Manager"
        },
        // {
        //     Pic: Sophia,
        //     Name: "Sophia Bukhari",
        //     Subname: "Business Development Manager"
        // },
        {
            Pic: Thameem,
            Name: "Thameem Ansari",
            Subname: "Senior Property Manager"
        },
         
        // {
        //     Pic: Pradip,
        //     Name: "Pradip Saha",
        //     Subname: "Senior Property Manager"
        // },
        {
            Pic: Eugenija,
            Name: "Eugenija Kovaliova",
            Subname: "Property Manager"
        },
        {
            Pic: Syed,
            Name: "Syed Abdul Khader",
            Subname: "Property Manager"
        },
        {
            Pic: Prathiba,
            Name: "Prathiba",
            Subname: "Property Manager"
        },

        {
            Pic: Felix,
            Name: "Felix Achinike Igwe",
            Subname: "Property Manager"
        },
        // {
        //     Pic: Dawn,
        //     Name: "Dawn Harding",
        //     Subname: "Property Manager"
        // },  
        // {
        //     Pic: Edward,
        //     Name: "Edward Asiedu",
        //     Subname: "Property Compliance Manager"
        // }, 
        {
            Pic: Madana,
            Name: "Madana Jahir",
            Subname: "Accountant"
        },
        {
            Pic: Abdul,
            Name: "Abdul Azzez",
            Subname: "Digital Marketing Executive"
        },

         {

            Pic: Oleg,
            Name: "Oleg Tsarev",
            Subname: "Maintenance Manager"
        }, {
            Pic: Sergi,
            Name: "Serhill Danyliuk",
            Subname: "Assistant Maintenace Manager"
        }
    ];
    return (
        <div className="OurTeam">
            <div className='container-fluid wholecontainer'>
                <h1>Our Team</h1>
                <p>Where the passion begins</p>
                <div className='row'>
                    {
                        Team.map((ele) => {
                            return (
                                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 teamimgcontainer'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <img src={ele.Pic} className={'img-fluid'}></img>
                                        </div>
                                        <div className='card-footer'>
                                            <h3>{ele.Name}</h3>
                                            <p>{ele.Subname}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Services;
