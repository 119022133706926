import React, { useRef, useState } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import Aboutourbus from './Aboutourbus';
import Ourstory from './Ourstory';
import OurTeam from './OurTeam';
import ContactUs1 from './ContactUs1';
import "../styles/MainPage.scss";
import "../styles/Fullaboutus.scss";
import { Toaster } from 'sonner';


function Fullaboutus(props){
  return(
      <div className='MainPage'>
         <NavBar setPopup={props.setPopup}></NavBar>
         <Aboutourbus></Aboutourbus>
         <Ourstory></Ourstory>
         <OurTeam></OurTeam>
         <ContactUs1></ContactUs1>
         <Footer></Footer>
      </div>
  )
}

export default Fullaboutus;