import '../styles/Services.scss';
import SolaceServiceslogo from "../assets/Images/SolaceServiceslogo.png";
import ServiceBanner from "../assets/Images/ServicesBanner.png";


function Services() {
    return (
        <div className="Services">

            <div className='Services-img'>
                <img src={ServiceBanner} className='img-fuild'></img>
            </div>
            <div className='container-fluid wholecontainer'>
                <div className='row'>
                    <div className='col-md-6 inner-wrapper'>
                        <h1 id="ServicesPage" className='ServicesTagServices'> Services</h1>
                        <div className='inner-image'>
                            <img src={SolaceServiceslogo} alt="SolaceServiceslogo" className='img-fluid  d-block SolaceServiceslogo'></img>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='ServicesParagraph'>
                            <p id="para1">Solace property management service replaces your physical property manager with a virtual one. Instead of relying on someone to keep everything up-to-date, our system does it all on autopilot — making the process of managing multiple properties easier than ever.
                            </p>
                            <p>
                                We join tenants, real-estate agents and landlords in a remarkably designed system. From end-to-end, our system unites tenants, landlords and agents to increase the flow of information, clarity of communication, and rapidness of response. Our aim is to digitalise managing properties using a user-friendly portal to alleviate the pressures of micromanaging multiple properties. Payments, maintenance, compliance, and support are all taken care of. Take back the reigns of your business. With us, all you have to do is one-click approve.
                            </p>
                            <p>
                                As we’re not estate agents ourselves, there’s no conflict of interest. Your clients will always remain yours — we’re just here to help you serve them better. Think of us as your secret weapon — working quietly in the background while you take all the credit.
                            </p>
                            {/* <p> */}
                                {/* Rise is your dedicated cloud-based property management portal. Connected through a single client interface, RISE takes care of tenants around the clock with a customer support team that handles all maintenance requests. Just sit back, relax, and watch the work get done.</p> */}
                                <p>Solace is your dedicated cloud-based property manager. Connected through a single client interface, Solace takes care of tenants around the clock with a customer support team that handles all maintenance requests. Just sit back, relax, and watch the work get done.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Services;
